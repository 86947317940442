import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Slider, SlidersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const SLIDER_URL = `${API_URL}/slider`

const getSliders = (query: string): Promise<SlidersQueryResponse> => {
  return axios.get(`${SLIDER_URL}?${query}`)
}

const getSliderById = (id: ID): Promise<Slider | undefined> => {
  return axios.get(`${SLIDER_URL}/${id}`)
}

const createSlider = (slider: Slider): Promise<Slider | undefined> => {
  return axios.post(SLIDER_URL, slider)
}

const updateSlider = (slider: Slider): Promise<Slider | undefined> => {
  return axios.patch(`${SLIDER_URL}`, slider)
}

const deleteSlider = (sliderId: ID): Promise<void> => {
  return axios.delete(`${SLIDER_URL}/${sliderId}`)
}

const deleteSelectedSliders = async (sliderIds: Array<ID>): Promise<void> => {
  const requests = sliderIds.map((id) => axios.delete(`${SLIDER_URL}/${id}`))
  await axios.all(requests)
}

export {
  getSliders,
  deleteSlider,
  deleteSelectedSliders,
  getSliderById,
  createSlider,
  updateSlider,
}
