import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Admission, AdmissionsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ADMISSION_URL = `${API_URL}/admission`

const getAdmissions = (query: string): Promise<AdmissionsQueryResponse> => {
  return axios.get(`${ADMISSION_URL}?${query}`)
}

const getAdmissionById = (id: ID): Promise<Admission | undefined> => {
  return axios.get(`${ADMISSION_URL}/${id}`)
}

const createAdmission = (admission: Admission): Promise<Admission | undefined> => {
  return axios.post(ADMISSION_URL, admission)
}

const updateAdmission = (admission: Admission): Promise<Admission | undefined> => {
  return axios.patch(`${ADMISSION_URL}`, admission)
}

const deleteAdmission = (admissionId: ID): Promise<void> => {
  return axios.delete(`${ADMISSION_URL}/${admissionId}`)
}

const deleteSelectedAdmissions = async (admissionIds: Array<ID>): Promise<void> => {
  const requests = admissionIds.map((id) => axios.delete(`${ADMISSION_URL}/${id}`))
  await axios.all(requests)
}

export {
  getAdmissions,
  deleteAdmission,
  deleteSelectedAdmissions,
  getAdmissionById,
  createAdmission,
  updateAdmission,
}
