import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {getTitle} from '../../_component/CustomFunctions'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const {privilege} = useAuth()
  useEffect(() => {
    const sideMenu = document.getElementById('#kt_aside_menu')
    const sideMenuLink = sideMenu?.querySelectorAll('a.menu-link')
    Array.from(sideMenuLink || []).forEach((col: any) => {
      const titles = col.href.split('/')
      const title = titles[titles.length - 1]
      const p = privilege?.find((p) => p.operation?.operation_slug === title)
      if (p?.is_view !== true && col.href !== window.location.origin + '/dashboard')
        col.parentElement.classList.add('d-none')
      else col.parentElement.classList.remove('d-none')
    })
    const sideMenuItem = sideMenu?.querySelectorAll('.menu-item.menu-accordion')
    Array.from(sideMenuItem || []).forEach((col: any) => {
      const subCount = col.querySelector('.menu-sub').childElementCount
      const subChildCount = col.querySelectorAll('.menu-sub>.d-none')?.length
      if (subCount <= subChildCount) col.classList.add('d-none')
      else col.classList.remove('d-none')
    })
  }, [privilege])

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/apps/profile/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps2/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  // const AccountsPage = lazy(() => import('../modules/apps/account/AccountsPage'))
  // const UsersPage = lazy(() => import('../modules/apps2/user1/UsersPage'))

  const AcademicYearsPage = lazy(() => import('../modules/apps/academic-year/AcademicYearsPage'))
  const AccountsPage = lazy(() => import('../modules/apps/account/AccountsPage'))
  const AssetCategorysPage = lazy(() => import('../modules/apps/asset-category/AssetCategorysPage'))
  const AssetIssuesPage = lazy(() => import('../modules/apps/asset-issue/AssetIssuesPage'))
  const AssetItemsPage = lazy(() => import('../modules/apps/asset-item/AssetItemsPage'))
  const AssetPurchasesPage = lazy(() => import('../modules/apps/asset-purchase/AssetPurchasesPage'))
  const AssetStocksPage = lazy(() => import('../modules/apps/asset-stock/AssetStocksPage'))
  const AdmitCardSettingsPage = lazy(
    () => import('../modules/apps/admit-card-setting/AdmitCardSettingsPage')
  )
  const ActivityLogsPage = lazy(() => import('../modules/apps/activity-log/ActivityLogsPage'))
  const AdmissionsPage = lazy(() => import('../modules/apps/admission/AdmissionsPage'))
  const AssetStoresPage = lazy(() => import('../modules/apps/asset-store/AssetStoresPage'))
  const AssignmentSubmissionsPage = lazy(
    () => import('../modules/apps/assignment-submission/AssignmentSubmissionsPage')
  )
  const AssignmentsPage = lazy(() => import('../modules/apps/assignment/AssignmentsPage'))
  const AwardsPage = lazy(() => import('../modules/apps/award/AwardsPage'))
  const BooksPage = lazy(() => import('../modules/apps/book/BooksPage'))
  const BookIssuesPage = lazy(() => import('../modules/apps/book-issue/BookIssuesPage'))
  const CertificatesPage = lazy(() => import('../modules/apps/certificate/CertificatesPage'))
  const ClasssPage = lazy(() => import('../modules/apps/class/ClasssPage'))
  const ClassGroupsPage = lazy(() => import('../modules/apps/class-group/ClassGroupsPage'))
  const ComplainTypesPage = lazy(() => import('../modules/apps/complain-type/ComplainTypesPage'))
  const ComplainsPage = lazy(() => import('../modules/apps/complain/ComplainsPage'))
  const DepartmentsPage = lazy(() => import('../modules/apps/department/DepartmentsPage'))
  const DesignationsPage = lazy(() => import('../modules/apps/designation/DesignationsPage'))
  const EbooksPage = lazy(() => import('../modules/apps/ebook/EbooksPage'))
  const EmailSettingsPage = lazy(() => import('../modules/apps/email-setting/EmailSettingsPage'))
  const EmailsPage = lazy(() => import('../modules/apps/email/EmailsPage'))
  const DiscountsPage = lazy(() => import('../modules/apps/discount/DiscountsPage'))
  const EmployeeAttendancesPage = lazy(
    () => import('../modules/apps/employee-attendance/EmployeeAttendancesPage')
  )
  const EnrollmentsPage = lazy(() => import('../modules/apps/enrollment/EnrollmentsPage'))
  const EmailTemplatesPage = lazy(() => import('../modules/apps/email-template/EmailTemplatesPage'))
  const EmployeesPage = lazy(() => import('../modules/apps/employee/EmployeesPage'))
  const EventsPage = lazy(() => import('../modules/apps/event/EventsPage'))
  const ExamAnswersPage = lazy(() => import('../modules/apps/exam-answer/ExamAnswersPage'))
  const ExamAttendancesPage = lazy(
    () => import('../modules/apps/exam-attendance/ExamAttendancesPage')
  )
  const ExamPsychomotorsPage = lazy(
    () => import('../modules/apps/exam-psychomotor/ExamPsychomotorsPage')
  )
  const ExamInstructionsPage = lazy(
    () => import('../modules/apps/exam-instruction/ExamInstructionsPage')
  )
  const ExamOnlineExamsPage = lazy(
    () => import('../modules/apps/exam-online-exam/ExamOnlineExamsPage')
  )
  const ExamQuestionsPage = lazy(() => import('../modules/apps/exam-question/ExamQuestionsPage'))
  const ExamResultsPage = lazy(() => import('../modules/apps/exam-result/ExamResultsPage'))
  const ExamSchedulesPage = lazy(() => import('../modules/apps/exam-schedule/ExamSchedulesPage'))
  const ExamTakenExamsPage = lazy(
    () => import('../modules/apps/exam-taken-exam/ExamTakenExamsPage')
  )
  const ExamsPage = lazy(() => import('../modules/apps/exam/ExamsPage'))
  const ExpenditureHeadsPage = lazy(
    () => import('../modules/apps/expenditure-head/ExpenditureHeadsPage')
  )
  const FaqsPage = lazy(() => import('../modules/apps/faq/FaqsPage'))
  const ExamToQuestionsPage = lazy(
    () => import('../modules/apps/exam-to-question/ExamToQuestionsPage')
  )
  const FeesAmountsPage = lazy(() => import('../modules/apps/fees-amount/FeesAmountsPage'))
  const GalleryImagesPage = lazy(() => import('../modules/apps/gallery-image/GalleryImagesPage'))
  const GallerysPage = lazy(() => import('../modules/apps/gallery/GallerysPage'))
  const FinalResultsPage = lazy(() => import('../modules/apps/final-result/FinalResultsPage'))
  const GmsmsSessionsPage = lazy(() => import('../modules/apps/gmsms-session/GmsmsSessionsPage'))
  const GradesPage = lazy(() => import('../modules/apps/grade/GradesPage'))
  const HolidaysPage = lazy(() => import('../modules/apps/holiday/HolidaysPage'))
  const HostelMembersPage = lazy(() => import('../modules/apps/hostel-member/HostelMembersPage'))
  const GlobalSettingsPage = lazy(() => import('../modules/apps/global-setting/GlobalSettingsPage'))
  const IncomeHeadsPage = lazy(() => import('../modules/apps/income-head/IncomeHeadsPage'))
  const InvoicesPage = lazy(() => import('../modules/apps/invoice/InvoicesPage'))
  const IdCardSettingsPage = lazy(
    () => import('../modules/apps/id-card-setting/IdCardSettingsPage')
  )
  const ItemCategorysPage = lazy(() => import('../modules/apps/item-category/ItemCategorysPage'))
  const ExpendituresPage = lazy(() => import('../modules/apps/expenditure/ExpendituresPage'))
  const ItemProductsPage = lazy(() => import('../modules/apps/item-product/ItemProductsPage'))
  const InvoiceDetailsPage = lazy(() => import('../modules/apps/invoice-detail/InvoiceDetailsPage'))
  const ItemSalesPage = lazy(() => import('../modules/apps/item-sale/ItemSalesPage'))
  const ItemPurchasesPage = lazy(() => import('../modules/apps/item-purchase/ItemPurchasesPage'))
  const ItemStocksPage = lazy(() => import('../modules/apps/item-stock/ItemStocksPage'))
  const ItemSuppliersPage = lazy(() => import('../modules/apps/item-supplier/ItemSuppliersPage'))
  const FeedbacksPage = lazy(() => import('../modules/apps/feedback/FeedbacksPage'))
  const LanguagesPage = lazy(() => import('../modules/apps/language/LanguagesPage'))
  const LeaveApplicationsPage = lazy(
    () => import('../modules/apps/leave-application/LeaveApplicationsPage')
  )
  const LeaveTypesPage = lazy(() => import('../modules/apps/leave-type/LeaveTypesPage'))
  const LibraryMembersPage = lazy(() => import('../modules/apps/library-member/LibraryMembersPage'))
  const LiveClasssPage = lazy(() => import('../modules/apps/live-class/LiveClasssPage'))
  const LpLessonDetailsPage = lazy(
    () => import('../modules/apps/lp-lesson-detail/LpLessonDetailsPage')
  )
  const LpLessonsPage = lazy(() => import('../modules/apps/lp-lesson/LpLessonsPage'))
  const LpTopicDetailsPage = lazy(
    () => import('../modules/apps/lp-topic-detail/LpTopicDetailsPage')
  )
  const LpTopicsPage = lazy(() => import('../modules/apps/lp-topic/LpTopicsPage'))
  const MarkEmailsPage = lazy(() => import('../modules/apps/mark-email/MarkEmailsPage'))
  const MarksPage = lazy(() => import('../modules/apps/mark/MarksPage'))
  const MarkSmsesPage = lazy(() => import('../modules/apps/mark-smse/MarkSmsesPage'))
  const MessageRelationshipsPage = lazy(
    () => import('../modules/apps/message-relationship/MessageRelationshipsPage')
  )
  const MessagesPage = lazy(() => import('../modules/apps/message/MessagesPage'))
  const ModulesPage = lazy(() => import('../modules/apps/module/ModulesPage'))
  const NewsPage = lazy(() => import('../modules/apps/news/NewsPage'))
  const NoticesPage = lazy(() => import('../modules/apps/notice/NoticesPage'))
  const OpeningHoursPage = lazy(() => import('../modules/apps/opening-hour/OpeningHoursPage'))
  const OperationsPage = lazy(() => import('../modules/apps/operation/OperationsPage'))
  const PaymentSettingsPage = lazy(
    () => import('../modules/apps/payment-setting/PaymentSettingsPage')
  )
  const PagesPage = lazy(() => import('../modules/apps/page/PagesPage'))
  const PhoneCallLogsPage = lazy(() => import('../modules/apps/phone-call-log/PhoneCallLogsPage'))
  const PostalReceiptsPage = lazy(() => import('../modules/apps/postal-receipt/PostalReceiptsPage'))
  const PrivilegesPage = lazy(() => import('../modules/apps/privilege/PrivilegesPage'))
  const PostalDispatchsPage = lazy(
    () => import('../modules/apps/postal-dispatch/PostalDispatchsPage')
  )
  const PurchasesPage = lazy(() => import('../modules/apps/purchase/PurchasesPage'))
  const RatingsPage = lazy(() => import('../modules/apps/rating/RatingsPage'))
  const ReplysPage = lazy(() => import('../modules/apps/reply/ReplysPage'))
  const ResultsPage = lazy(() => import('../modules/apps/result/ResultsPage'))
  const ResultCardsPage = lazy(() => import('../modules/apps/result-card/ResultCardsPage'))
  const RouteStopsPage = lazy(() => import('../modules/apps/route-stop/RouteStopsPage'))
  const RolesPage = lazy(() => import('../modules/apps/role/RolesPage'))
  const RoomsPage = lazy(() => import('../modules/apps/room/RoomsPage'))
  const RoutesPage = lazy(() => import('../modules/apps/route/RoutesPage'))
  const RoutinesPage = lazy(() => import('../modules/apps/routine/RoutinesPage'))
  const SaasFaqsPage = lazy(() => import('../modules/apps/saas-faq/SaasFaqsPage'))
  const SaasPlansPage = lazy(() => import('../modules/apps/saas-plan/SaasPlansPage'))
  const SaasSettingsPage = lazy(() => import('../modules/apps/saas-setting/SaasSettingsPage'))
  const SaasSlidersPage = lazy(() => import('../modules/apps/saas-slider/SaasSlidersPage'))
  const SaasSubscriptionsPage = lazy(
    () => import('../modules/apps/saas-subscription/SaasSubscriptionsPage')
  )
  const SalaryGradesPage = lazy(() => import('../modules/apps/salary-grade/SalaryGradesPage'))
  const ItemWarehousesPage = lazy(() => import('../modules/apps/item-warehouse/ItemWarehousesPage'))
  const SectionsPage = lazy(() => import('../modules/apps/section/SectionsPage'))
  const SchoolsPage = lazy(() => import('../modules/apps/school/SchoolsPage'))
  const SlidersPage = lazy(() => import('../modules/apps/slider/SlidersPage'))
  const AboutsPage = lazy(() => import('../modules/apps/about/AboutsPage'))
  const HostelsPage = lazy(() => import('../modules/apps/hostel/HostelsPage'))
  const SmsSettingsPage = lazy(() => import('../modules/apps/sms-setting/SmsSettingsPage'))
  const SsBalancesPage = lazy(() => import('../modules/apps/ss-balance/SsBalancesPage'))
  const SsCandidatesPage = lazy(() => import('../modules/apps/ss-candidate/SsCandidatesPage'))
  const SsDonorsPage = lazy(() => import('../modules/apps/ss-donor/SsDonorsPage'))
  const SsScholarshipsPage = lazy(() => import('../modules/apps/ss-scholarship/SsScholarshipsPage'))
  const StudentActivitysPage = lazy(
    () => import('../modules/apps/student-activity/StudentActivitysPage')
  )
  const StudentAttendancesPage = lazy(
    () => import('../modules/apps/student-attendance/StudentAttendancesPage')
  )
  const StudentTypesPage = lazy(() => import('../modules/apps/student-type/StudentTypesPage'))
  const StudentsPage = lazy(() => import('../modules/apps/student/StudentsPage'))
  const StudyMaterialsPage = lazy(() => import('../modules/apps/study-material/StudyMaterialsPage'))
  const SubjectsPage = lazy(() => import('../modules/apps/subject/SubjectsPage'))
  const SuggestionsPage = lazy(() => import('../modules/apps/suggestion/SuggestionsPage'))
  const SyllabussPage = lazy(() => import('../modules/apps/syllabus/SyllabussPage'))
  const TeacherAttendancesPage = lazy(
    () => import('../modules/apps/teacher-attendance/TeacherAttendancesPage')
  )
  const TeachersPage = lazy(() => import('../modules/apps/teacher/TeachersPage'))
  const SystemAdminsPage = lazy(() => import('../modules/apps/system-admin/SystemAdminsPage'))
  const TextMessagesPage = lazy(() => import('../modules/apps/text-message/TextMessagesPage'))
  const ThemesPage = lazy(() => import('../modules/apps/theme/ThemesPage'))
  const TransactionsPage = lazy(() => import('../modules/apps/transaction/TransactionsPage'))
  const TransportMembersPage = lazy(
    () => import('../modules/apps/transport-member/TransportMembersPage')
  )
  const TodosPage = lazy(() => import('../modules/apps/todo/TodosPage'))
  const UsersPage = lazy(() => import('../modules/apps/user/UsersPage'))
  const VehiclesPage = lazy(() => import('../modules/apps/vehicle/VehiclesPage'))
  const VisitorsPage = lazy(() => import('../modules/apps/visitor/VisitorsPage'))
  const VisitorPurposesPage = lazy(
    () => import('../modules/apps/visitor-purpose/VisitorPurposesPage')
  )
  const ClassLecturesPage = lazy(() => import('../modules/apps/class-lecture/ClassLecturesPage'))
  const SalaryPaymentsPage = lazy(() => import('../modules/apps/salary-payment/SalaryPaymentsPage'))
  const SmsTemplatesPage = lazy(() => import('../modules/apps/sms-template/SmsTemplatesPage'))
  const ItemIssuesPage = lazy(() => import('../modules/apps/item-issue/ItemIssuesPage'))
  const PromotionsPage = lazy(() => import('../modules/apps/promotion/PromotionsPage'))
  const IdCardsPage = lazy(() => import('../modules/apps/id-card/IdCardsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView1>
              <AccountPage />
            </SuspensedView1>
          }
        />

        <Route
          path='apps/admin/academic-year/*'
          element={
            <SuspensedView>
              <AcademicYearsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/admin/account/*'
          element={
            <SuspensedView>
              <AccountsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/asset-category/*'
          element={
            <SuspensedView>
              <AssetCategorysPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/asset-issue/*'
          element={
            <SuspensedView>
              <AssetIssuesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/asset-item/*'
          element={
            <SuspensedView>
              <AssetItemsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/asset-purchase/*'
          element={
            <SuspensedView>
              <AssetPurchasesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/asset-stock/*'
          element={
            <SuspensedView>
              <AssetStocksPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/admit-card-setting/*'
          element={
            <SuspensedView>
              <AdmitCardSettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/activity-log/*'
          element={
            <SuspensedView>
              <ActivityLogsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/student-management/admission/*'
          element={
            <SuspensedView>
              <AdmissionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/asset-store/*'
          element={
            <SuspensedView>
              <AssetStoresPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/academic/assignment-submission/*'
          element={
            <SuspensedView>
              <AssignmentSubmissionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/academic/assignment/*'
          element={
            <SuspensedView>
              <AssignmentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/award/*'
          element={
            <SuspensedView>
              <AwardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/book/*'
          element={
            <SuspensedView>
              <BooksPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/book-issue/*'
          element={
            <SuspensedView>
              <BookIssuesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/certificate/*'
          element={
            <SuspensedView>
              <CertificatesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/academic/class/*'
          element={
            <SuspensedView>
              <ClasssPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/class-group/*'
          element={
            <SuspensedView>
              <ClassGroupsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/complain-type/*'
          element={
            <SuspensedView>
              <ComplainTypesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/complain/*'
          element={
            <SuspensedView>
              <ComplainsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/employee-management/department/*'
          element={
            <SuspensedView>
              <DepartmentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/employee-management/designation/*'
          element={
            <SuspensedView>
              <DesignationsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/ebook/*'
          element={
            <SuspensedView>
              <EbooksPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/email-setting/*'
          element={
            <SuspensedView>
              <EmailSettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/email/*'
          element={
            <SuspensedView>
              <EmailsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/discount/*'
          element={
            <SuspensedView>
              <DiscountsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/employee-management/employee-attendance/*'
          element={
            <SuspensedView>
              <EmployeeAttendancesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/enrollment/*'
          element={
            <SuspensedView>
              <EnrollmentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/email-template/*'
          element={
            <SuspensedView>
              <EmailTemplatesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/employee-management/employee/*'
          element={
            <SuspensedView>
              <EmployeesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/event/*'
          element={
            <SuspensedView>
              <EventsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam-answer/*'
          element={
            <SuspensedView>
              <ExamAnswersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam/exam-attendance/*'
          element={
            <SuspensedView>
              <ExamAttendancesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam-instruction/*'
          element={
            <SuspensedView>
              <ExamInstructionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam-online-exam/*'
          element={
            <SuspensedView>
              <ExamOnlineExamsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam-question/*'
          element={
            <SuspensedView>
              <ExamQuestionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/examresult/*'
          element={
            <SuspensedView>
              <ExamResultsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/promotion/*'
          element={
            <SuspensedView>
              <PromotionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam/schedule/*'
          element={
            <SuspensedView>
              <ExamSchedulesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam-taken-exam/*'
          element={
            <SuspensedView>
              <ExamTakenExamsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam/exam/*'
          element={
            <SuspensedView>
              <ExamsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/expenditure-head/*'
          element={
            <SuspensedView>
              <ExpenditureHeadsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/faq/*'
          element={
            <SuspensedView>
              <FaqsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam-to-question/*'
          element={
            <SuspensedView>
              <ExamToQuestionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/fees-amount/*'
          element={
            <SuspensedView>
              <FeesAmountsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/gallery/image/*'
          element={
            <SuspensedView>
              <GalleryImagesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/gallery/*'
          element={
            <SuspensedView>
              <GallerysPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/finalresult/*'
          element={
            <SuspensedView>
              <FinalResultsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/gmsms-session/*'
          element={
            <SuspensedView>
              <GmsmsSessionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/exam/grade/*'
          element={
            <SuspensedView>
              <GradesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/holiday/*'
          element={
            <SuspensedView>
              <HolidaysPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/hostel-member/*'
          element={
            <SuspensedView>
              <HostelMembersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/global-setting/*'
          element={
            <SuspensedView>
              <GlobalSettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/income-head/*'
          element={
            <SuspensedView>
              <IncomeHeadsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/invoice/*'
          element={
            <SuspensedView>
              <InvoicesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/id-cards/*'
          element={
            <SuspensedView>
              <IdCardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/id-card-setting/*'
          element={
            <SuspensedView>
              <IdCardSettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/item-category/*'
          element={
            <SuspensedView>
              <ItemCategorysPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/expenditure/*'
          element={
            <SuspensedView>
              <ExpendituresPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/item-product/*'
          element={
            <SuspensedView>
              <ItemProductsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/invoice-detail/*'
          element={
            <SuspensedView>
              <InvoiceDetailsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/item-sale/*'
          element={
            <SuspensedView>
              <ItemSalesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/item-purchase/*'
          element={
            <SuspensedView>
              <ItemPurchasesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/item-stock/*'
          element={
            <SuspensedView>
              <ItemStocksPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/item-supplier/*'
          element={
            <SuspensedView>
              <ItemSuppliersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/feedback/*'
          element={
            <SuspensedView>
              <FeedbacksPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/language/*'
          element={
            <SuspensedView>
              <LanguagesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/leave-application/*'
          element={
            <SuspensedView>
              <LeaveApplicationsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/leave-type/*'
          element={
            <SuspensedView>
              <LeaveTypesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/library-member/*'
          element={
            <SuspensedView>
              <LibraryMembersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/live-class/*'
          element={
            <SuspensedView>
              <LiveClasssPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/lp-lesson-detail/*'
          element={
            <SuspensedView>
              <LpLessonDetailsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/lp-lesson/*'
          element={
            <SuspensedView>
              <LpLessonsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/lp-topic-detail/*'
          element={
            <SuspensedView>
              <LpTopicDetailsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/lp-topic/*'
          element={
            <SuspensedView>
              <LpTopicsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/mark-email/*'
          element={
            <SuspensedView>
              <MarkEmailsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/mark/*'
          element={
            <SuspensedView>
              <MarksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/marksheet/*'
          element={
            <SuspensedView>
              <MarksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/psychomotor/marksheet/*'
          element={
            <SuspensedView>
              <ExamPsychomotorsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/mark-smse/*'
          element={
            <SuspensedView>
              <MarkSmsesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/message-relationship/*'
          element={
            <SuspensedView>
              <MessageRelationshipsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/message/*'
          element={
            <SuspensedView>
              <MessagesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/module/*'
          element={
            <SuspensedView>
              <ModulesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/frontend/news/*'
          element={
            <SuspensedView>
              <NewsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/notice/*'
          element={
            <SuspensedView>
              <NoticesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/opening-hour/*'
          element={
            <SuspensedView>
              <OpeningHoursPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/operation/*'
          element={
            <SuspensedView1>
              <OperationsPage />
            </SuspensedView1>
          }
        />

        <Route
          path='apps/payment-setting/*'
          element={
            <SuspensedView>
              <PaymentSettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/page/*'
          element={
            <SuspensedView>
              <PagesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/phone-call-log/*'
          element={
            <SuspensedView>
              <PhoneCallLogsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/postal-receipt/*'
          element={
            <SuspensedView>
              <PostalReceiptsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/admin/permission/*'
          element={
            <SuspensedView>
              <PrivilegesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/postal-dispatch/*'
          element={
            <SuspensedView>
              <PostalDispatchsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/purchase/*'
          element={
            <SuspensedView>
              <PurchasesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/rating/*'
          element={
            <SuspensedView>
              <RatingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/reply/*'
          element={
            <SuspensedView>
              <ReplysPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/result/*'
          element={
            <SuspensedView>
              <ResultsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/resultcard/*'
          element={
            <SuspensedView>
              <ResultCardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/route-stop/*'
          element={
            <SuspensedView>
              <RouteStopsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/admin/role/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/room/*'
          element={
            <SuspensedView>
              <RoomsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/route/*'
          element={
            <SuspensedView>
              <RoutesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/routine/*'
          element={
            <SuspensedView>
              <RoutinesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/saas-faq/*'
          element={
            <SuspensedView>
              <SaasFaqsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/saas-plan/*'
          element={
            <SuspensedView>
              <SaasPlansPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/saas-setting/*'
          element={
            <SuspensedView>
              <SaasSettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/saas-slider/*'
          element={
            <SuspensedView>
              <SaasSlidersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/saas-subscription/*'
          element={
            <SuspensedView>
              <SaasSubscriptionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/salary-grade/*'
          element={
            <SuspensedView>
              <SalaryGradesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/item-warehouse/*'
          element={
            <SuspensedView>
              <ItemWarehousesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/academic/section/*'
          element={
            <SuspensedView>
              <SectionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/admin/school/*'
          element={
            <SuspensedView>
              <SchoolsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/frontend/slider/*'
          element={
            <SuspensedView>
              <SlidersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/frontend/about/*'
          element={
            <SuspensedView>
              <AboutsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/hostel/*'
          element={
            <SuspensedView>
              <HostelsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/sms-setting/*'
          element={
            <SuspensedView>
              <SmsSettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/ss-balance/*'
          element={
            <SuspensedView>
              <SsBalancesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/ss-candidate/*'
          element={
            <SuspensedView>
              <SsCandidatesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/ss-donor/*'
          element={
            <SuspensedView>
              <SsDonorsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/ss-scholarship/*'
          element={
            <SuspensedView>
              <SsScholarshipsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/student-activity/*'
          element={
            <SuspensedView>
              <StudentActivitysPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/student-management/student-attendance/*'
          element={
            <SuspensedView>
              <StudentAttendancesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/student-management/student-type/*'
          element={
            <SuspensedView>
              <StudentTypesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/student-management/student/*'
          element={
            <SuspensedView>
              <StudentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/academic/study-material/*'
          element={
            <SuspensedView>
              <StudyMaterialsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/academic/subject/*'
          element={
            <SuspensedView>
              <SubjectsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/suggestion/*'
          element={
            <SuspensedView>
              <SuggestionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/academic/syllabus/*'
          element={
            <SuspensedView>
              <SyllabussPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/teacher-attendance/*'
          element={
            <SuspensedView>
              <TeacherAttendancesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/teacher/*'
          element={
            <SuspensedView>
              <TeachersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/system-admin/*'
          element={
            <SuspensedView>
              <SystemAdminsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/text-message/*'
          element={
            <SuspensedView>
              <TextMessagesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/theme/*'
          element={
            <SuspensedView>
              <ThemesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/transaction/*'
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/transport-member/*'
          element={
            <SuspensedView>
              <TransportMembersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/todo/*'
          element={
            <SuspensedView>
              <TodosPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/user/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/vehicle/*'
          element={
            <SuspensedView>
              <VehiclesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/visitor/*'
          element={
            <SuspensedView>
              <VisitorsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/visitor-purpose/*'
          element={
            <SuspensedView>
              <VisitorPurposesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/academic/class-lecture/*'
          element={
            <SuspensedView>
              <ClassLecturesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/salary-payment/*'
          element={
            <SuspensedView>
              <SalaryPaymentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/sms-template/*'
          element={
            <SuspensedView>
              <SmsTemplatesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/item-issue/*'
          element={
            <SuspensedView>
              <ItemIssuesPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/accounts/*'
          element={
            <SuspensedView>
              <AccountsPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const {pathname} = useLocation()
  const title = getTitle(pathname)
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  const {privilege} = useAuth()
  const visible = () => {
    let res = false
    const result = privilege?.find((p) => p.operation?.operation_slug === title.toLowerCase())
    if (result) res = result['is_view'] || false
    return res
  }
  return visible() ? (
    <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
  ) : (
    <Routes>
      <Route path='*' element={<Navigate to='/dashboard' />} />
    </Routes>
  )
}
const SuspensedView1: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
