import {FC} from 'react'
import {useAuth} from '../../modules/auth'

export const ContactPage: FC = () => {
  const {school} = useAuth()

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid mt-5'>
      {/*begin::Content container*/}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/*begin::Contact*/}
        <div className='card'>
          {/*begin::Body*/}
          <div className='card-body p-lg-17'>
            {/*begin::Row*/}
            <div className='row mb-3'>
              {/*begin::Col*/}
              <div className='col-md-6 pe-lg-10'>
                {/*begin::Form*/}
                <form action='' className='form mb-15' method='post' id='kt_contact_form'>
                  <h1 className='fw-bold text-dark mb-9'>Send Us Email</h1>
                  {/*begin::Input group*/}
                  <div className='row mb-5'>
                    {/*begin::Col*/}
                    <div className='col-md-6 fv-row'>
                      {/*begin::Label*/}
                      <label className='fs-5 fw-semibold mb-2'>Name</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder=''
                        name='name'
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className='col-md-6 fv-row'>
                      {/*end::Label*/}
                      <label className='fs-5 fw-semibold mb-2'>Email</label>
                      {/*end::Label*/}
                      {/*end::Input*/}
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder=''
                        name='email'
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className='d-flex flex-column mb-5 fv-row'>
                    {/*begin::Label*/}
                    <label className='fs-5 fw-semibold mb-2'>Subject</label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      className='form-control form-control-solid'
                      placeholder=''
                      name='subject'
                    />
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className='d-flex flex-column mb-10 fv-row'>
                    <label className='fs-6 fw-semibold mb-2'>Message</label>
                    <textarea
                      className='form-control form-control-solid'
                      rows={6}
                      name='message'
                      placeholder=''
                    ></textarea>
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Submit*/}
                  <button type='submit' className='btn btn-primary' id='kt_contact_submit_button'>
                    {/*begin::Indicator label*/}
                    <span className='indicator-label'>Submit</span>
                    {/*end::Indicator label*/}
                    {/*begin::Indicator progress*/}
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    {/*end::Indicator progress*/}
                  </button>
                  {/*end::Submit*/}
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className='col-md-6 ps-lg-10'>
                {/*begin::Map*/}
                {school?.google_map && (
                  <iframe
                    src={school.google_map}
                    width='100%'
                    height='450'
                    style={{border: 0}}
                    allowFullScreen={true}
                    loading='lazy'
                    referrerPolicy='no-referrer-when-downgrade'
                    title='school-map'
                  ></iframe>
                )}
                {/* <div
                    id='kt_contact_map'
                    className='w-100 rounded mb-2 mb-lg-0 mt-2'
                    style={{height: '486px'}}
                  ></div> */}
                {/*end::Map*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className='row g-5 mb-5 mb-lg-15'>
              {/*begin::Col*/}
              <div className='col-sm-6 pe-lg-10'>
                {/*begin::Phone*/}
                <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100'>
                  {/*begin::Icon*/}
                  {/*SVG file not found: icons/duotune/finance/fin006.svgPhone.svg*/}
                  {/*end::Icon*/}
                  {/*begin::Subtitle*/}
                  <h1 className='text-dark fw-bold my-5'>Let’s Speak</h1>
                  {/*end::Subtitle*/}
                  {/*begin::Number*/}
                  <div className='text-gray-700 fw-semibold fs-2'>{school?.phone}</div>
                  {/*end::Number*/}
                </div>
                {/*end::Phone*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className='col-sm-6 ps-lg-10'>
                {/*begin::Address*/}
                <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100'>
                  {/*begin::Icon*/}
                  {/*begin::Svg Icon | path: icons/duotune/general/gen018.svg*/}
                  <span className='svg-icon svg-icon-3tx svg-icon-primary'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        opacity='0.3'
                        d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                        fill='currentColor'
                      />
                      <path
                        d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Icon*/}
                  {/*begin::Subtitle*/}
                  <h1 className='text-dark fw-bold my-5'>Our Address</h1>
                  {/*end::Subtitle*/}
                  {/*begin::Description*/}
                  <div className='text-gray-700 fs-3 fw-semibold'>{school?.address}</div>
                  {/*end::Description*/}
                </div>
                {/*end::Address*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Contact*/}
      </div>
      {/*end::Content container*/}
    </div>
  )
}
