/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {getMyPrivileges} from '../../../../app/modules/apps/privilege/privileges-list/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import {getUserByToken, switchAccount} from '../../../../app/modules/auth/core/_requests'
// import {Languages} from './Languages'
import {ID, toAbsoluteUrl} from '../../../helpers'
import {fileSrc} from '../../../../_component/fileUtils'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout, saveAuth, setCurrentUser, savePrivilege} = useAuth()
  const accountSwitch = async (account_id: ID) => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
    try {
      const data = await switchAccount(account_id)
      saveAuth(data)
      const privileges = await getMyPrivileges()
      savePrivilege(privileges.entities)
      const user = await getUserByToken()
      setCurrentUser(user)
      if (splashScreen) splashScreen.style.setProperty('display', 'none')
    } catch (error: any) {
      console.error(error)
      saveAuth(undefined)
      if (splashScreen) splashScreen.style.setProperty('display', 'none')
    }
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              src={
                currentUser?.user?.image
                  ? fileSrc(currentUser.user.image)
                  : toAbsoluteUrl('/media/avatars/blank.png')
              }
              alt={currentUser?.user?.first_name}
            />
          </div>

          <div className='d-flex flex-column overflow-hidden'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.user?.first_name} {currentUser?.user?.last_name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <span className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.user?.email}
            </span>
            <span className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.role_name}
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      {currentUser?.accounts && currentUser?.accounts?.length > 1 && (
        <div
          className='menu-item px-5'
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='left-start'
          data-kt-menu-flip='bottom'
        >
          <a href='#' className='menu-link px-5'>
            <span className='menu-title'>Switch Account</span>
            <span className='menu-arrow'></span>
          </a>

          <div className='menu-sub menu-sub-dropdown w-175px py-4'>
            {currentUser?.accounts?.map((a) => (
              <div key={a.id} className='menu-item px-3'>
                <a
                  onClick={() => accountSwitch(a.id)}
                  className={`menu-link px-5 ${currentUser.account_id === a.id ? 'active' : ''}`}
                >
                  {a.role_name}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
