import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Employee, EmployeesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const EMPLOYEE_URL = `${API_URL}/employee`

const getEmployees = (query: string): Promise<EmployeesQueryResponse> => {
  return axios.get(`${EMPLOYEE_URL}?${query}`)
}

const getEmployeeById = (id: ID): Promise<Employee | undefined> => {
  return axios.get(`${EMPLOYEE_URL}/${id}`)
}

const createEmployee = (employee: Employee): Promise<Employee | undefined> => {
  return axios.post(EMPLOYEE_URL, employee)
}

const updateEmployee = (employee: Employee): Promise<Employee | undefined> => {
  return axios.patch(`${EMPLOYEE_URL}`, employee)
}

const deleteEmployee = (employeeId: ID): Promise<void> => {
  return axios.delete(`${EMPLOYEE_URL}/${employeeId}`)
}

const deleteSelectedEmployees = async (employeeIds: Array<ID>): Promise<void> => {
  const requests = employeeIds.map((id) => axios.delete(`${EMPLOYEE_URL}/${id}`))
  await axios.all(requests)
}

export {
  getEmployees,
  deleteEmployee,
  deleteSelectedEmployees,
  getEmployeeById,
  createEmployee,
  updateEmployee,
}
