import React from 'react'
import {useAuth} from '../../../../app/modules/auth'

export function MenuInner() {
  const {school} = useAuth()
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='d-flex align-items-center fs-1'>{school?.name}</div>
    </div>
  )
}
