import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useAuth} from '../../modules/auth'
import {fileSrc} from '../../../_component/fileUtils'

export const Footer: FC = () => {
  const {school} = useAuth()

  return school ? (
    <>
      {/*begin::Footer Section*/}
      <div className='mb-0 mt-10 mt-lg-20'>
        {/*begin::Curve top*/}
        <div className='landing-curve landing-dark-color'>
          <svg viewBox='15 -1 1470 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z'
              fill='currentColor'
            ></path>
          </svg>
        </div>
        {/*end::Curve top*/}
        {/*begin::Wrapper*/}
        <div className='landing-dark-bg pt-20'>
          {/*begin::Container*/}
          <div className='container'>
            {/*begin::Row*/}
            <div className='row py-10 py-lg-20'>
              {/*begin::Col*/}
              <div className='col-lg-6 pe-lg-16 mb-10 mb-lg-0'>
                {/*begin::Block*/}
                <div className='rounded landing-dark-border p-9 mb-10'>
                  {/*begin::Title*/}
                  <div className='row'>
                    <div className='col-lg-4'>
                      <img
                        alt='Logo'
                        src={
                          school?.logo
                            ? fileSrc(school?.logo)
                            : toAbsoluteUrl('/media/logos/starlight-logo.png')
                        }
                        className='logo-default h-100px h-lg-150px'
                      />
                    </div>
                    <div className='col-lg-8'>
                      <div className='d-flex fw-semibold flex-column ms-lg-10'>
                        {/*begin::Link*/}
                        <div className='mb-6'>
                          <span className='svg-icon svg-icon-muted svg-icon-2hx me-2'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z'
                                fill='currentColor'
                              />
                              <path
                                opacity='0.3'
                                d='M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          <span className='text-white opacity-50 text-hover-primary fs-5 mb-6'>
                            {school.phone}
                          </span>
                        </div>
                        {/*end::Link*/}
                        {/*begin::Link*/}
                        <div className='mb-6'>
                          <span className='svg-icon svg-icon-muted svg-icon-2hx me-2'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z'
                                fill='currentColor'
                              />
                              <path
                                d='M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          <span className='text-white opacity-50 text-hover-primary fs-5 mb-6'>
                            {school.email}
                          </span>
                        </div>
                        {/*end::Link*/}
                        {/*begin::Link*/}
                        <div className='mb-6'>
                          <span className='svg-icon svg-icon-muted svg-icon-2hx me-2'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                fill='currentColor'
                              />
                              <path
                                d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          <span className='text-white opacity-50 text-hover-primary fs-5 mb-6'>
                            {school.address}
                          </span>
                        </div>
                        {/*end::Link*/}
                      </div>
                    </div>
                  </div>
                  {/*end::Text*/}
                </div>
                {/*end::Block*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className='col-lg-6 ps-lg-16'>
                {/*begin::Navs*/}
                <div className='d-flex justify-content-center'>
                  {/*begin::Links*/}
                  <div className='d-flex fw-semibold flex-column me-20'>
                    {/*begin::Subtitle*/}
                    <h4 className='fw-bold text-gray-400 mb-6'>Quick Links</h4>
                    {/*end::Subtitle*/}
                    {/*begin::Link*/}
                    <Link
                      to='/#about'
                      className='text-white opacity-50 text-hover-primary fs-5 mb-6'
                    >
                      About Us
                    </Link>
                    {/*end::Link*/}
                    <Link
                      to='/contact-us'
                      className='text-white opacity-50 text-hover-primary fs-5 mb-6'
                    >
                      Contact Us
                    </Link>
                    {/*end::Link*/}
                    {/*begin::Link*/}
                    <Link
                      to='/#admin'
                      className='text-white opacity-50 text-hover-primary fs-5 mb-6'
                    >
                      Admin
                    </Link>
                    {/*end::Link*/}
                    {/*begin::Link*/}
                    <Link
                      to='/#announcement'
                      className='text-white opacity-50 text-hover-primary fs-5 mb-6'
                    >
                      Announcement
                    </Link>
                    {/*end::Link*/}
                  </div>
                  {/*end::Links*/}
                  {/*begin::Links*/}
                  <div className='d-flex fw-semibold flex-column ms-lg-20'>
                    {/*begin::Subtitle*/}
                    <h4 className='fw-bold text-gray-400 mb-6'>Stay Connected</h4>
                    {/*end::Subtitle*/}
                    {/*begin::Link*/}
                    <Link to={school.facebook_url || ''} className='mb-6'>
                      <img
                        src='/media/svg/brand-logos/facebook-4.svg'
                        className='h-20px me-2'
                        alt=''
                      />
                      <span className='text-white opacity-50 text-hover-primary fs-5 mb-6'>
                        Facebook
                      </span>
                    </Link>
                    {/*end::Link*/}
                    {/*begin::Link*/}
                    <Link to={school.twitter_url || ''} className='mb-6'>
                      <img
                        src='/media/svg/brand-logos/twitter.svg'
                        className='h-20px me-2'
                        alt=''
                      />
                      <span className='text-white opacity-50 text-hover-primary fs-5 mb-6'>
                        Twitter
                      </span>
                    </Link>
                    {/*end::Link*/}
                    {/*begin::Link*/}
                    <Link to={school.youtube_url || ''} className='mb-6'>
                      <img
                        src='/media/svg/brand-logos/youtube-3.svg'
                        className='h-20px me-2'
                        alt=''
                      />
                      <span className='text-white opacity-50 text-hover-primary fs-5 mb-6'>
                        Youtube
                      </span>
                    </Link>
                    {/*end::Link*/}
                    {/*begin::Link*/}
                    <Link to={school.instagram_url || ''} className='mb-6'>
                      <img
                        src='/media/svg/brand-logos/instagram-2-1.svg'
                        className='h-20px me-2'
                        alt=''
                      />
                      <span className='text-white opacity-50 text-hover-primary fs-5 mb-6'>
                        Instagram
                      </span>
                    </Link>
                    {/*end::Link*/}
                  </div>
                  {/*end::Links*/}
                </div>
                {/*end::Navs*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          </div>
          {/*end::Container*/}
          {/*begin::Separator*/}
          <div className='landing-dark-separator'></div>
          {/*end::Separator*/}
          {/*begin::Container*/}
          <div className='container'>
            {/*begin::Wrapper*/}
            <div className='d-flex flex-column flex-md-row flex-stack py-2 py-lg-2'>
              {/*begin::Copyright*/}
              <div className='d-flex align-items-center order-2 order-md-1'>
                {/*begin::Logo*/}
                {/* <a href='../../demo1/dist/landing.html'>
                  <img alt='Logo' src='/media/logos/landing.svg' className='h-15px h-md-20px' />
                </a> */}
                {/*end::Logo image*/}
                {/*begin::Logo image*/}
                <a
                  className='mx-5 fs-6 fw-semibold text-gray-600 pt-1'
                  href='https://starlightesolutions.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  © {new Date().getFullYear()} Starlight Inc.
                </a>
                {/*end::Logo image*/}
              </div>
              {/*end::Copyright*/}
              {/*begin::Menu*/}
              <ul className='menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0'>
                <li className='menu-item'>
                  <a
                    href='https://starlightesolutions.com'
                    target='_blank'
                    className='menu-link px-2'
                    rel='noreferrer'
                  >
                    About
                  </a>
                </li>
                <li className='menu-item mx-5'>
                  <a
                    href='https://starlightesolutions.com'
                    target='_blank'
                    className='menu-link px-2'
                    rel='noreferrer'
                  >
                    Support
                  </a>
                </li>
              </ul>
              {/*end::Menu*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::Footer Section*/}
      {/*begin::Scrolltop*/}
      <div id='kt_scrolltop' className='scrolltop' data-kt-scrolltop='true'>
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
        <span className='svg-icon'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              opacity='0.5'
              x='13'
              y='6'
              width='13'
              height='2'
              rx='1'
              transform='rotate(90 13 6)'
              fill='currentColor'
            />
            <path
              d='M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z'
              fill='currentColor'
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}
      </div>
      {/*end::Scrolltop*/}
    </>
  ) : (
    <h2 className='m-auto text-danger'>School Data Not Found</h2>
  )
}
