import React, {FC, SetStateAction, useEffect, useState} from 'react'
import {ID, QUERIES} from '../../../../../../../../../_metronic/helpers'
import {Field, ErrorMessage, FormikValues} from 'formik'
import {useQuery} from '@tanstack/react-query'
import {getStudentTypes} from '../../../../../../student-type/student-types-list/core/_requests'
import {getClasss} from '../../../../../../class/classs-list/core/_requests'
import {getSections} from '../../../../../../section/sections-list/core/_requests'
import moment from 'moment'
import {FileUpload} from '../../../../../../../../../_component/CustomInput'

const AdmissionInfo: FC<FormikValues> = ({formik, isExternal}) => {
  const [classId, setClassId] = useState<SetStateAction<ID>>(formik.values.enrollment?.class_id)
  useEffect(() => {
    formik.values.enrollment?.class_id && setClassId(formik.values.enrollment?.class_id)
  }, [formik.values.enrollment?.class_id])

  const {data: student_type} = useQuery(
    [`${QUERIES.STUDENT_TYPES_LIST}`],
    () => {
      return getStudentTypes('filter={"status":true}')
    },
    {cacheTime: Infinity, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const {data: _class} = useQuery(
    [`${QUERIES.CLASSS_LIST}-filter={"status":true}`],
    () => {
      return getClasss('filter={"status":true}')
    },
    {cacheTime: Infinity, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const {data: section} = useQuery(
    [`${QUERIES.SECTIONS_LIST}-filter={"status":true,"class_id":${classId}}`],
    () => {
      return getSections(`filter={"status":true,"class_id":${classId}}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: classId ? true : false,
    }
  )
  useEffect(() => {
    if (section?.entities?.length && isExternal)
      formik.setFieldValue('enrollment[section_id]', section.entities[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section])

  return (
    <div className='w-100'>
      <div className='row'>
        {!isExternal && (
          <>
            <div className='mb-5 fv-row col-lg-4'>
              <label className='form-label mb-3'>Admission Number</label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='admission_no'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='admission_no' />
              </div>
            </div>
            <div className='mb-5 fv-row col-lg-4'>
              <label className='form-label mb-3'>Admission Date</label>

              <Field
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='admission_date'
                value={moment(formik.values.admission_date).format('YYYY-MM-DD')}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='admission_date' />
              </div>
            </div>
            <div className='mb-5 fv-row col-lg-4'>
              <label className='form-label mb-3'>Registration Number</label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='registration_no'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='registration_no' />
              </div>
            </div>
          </>
        )}
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Student Type</label>

          <Field
            as='select'
            name='student_type_id'
            className='form-select form-select-lg form-select-solid'
          >
            <option></option>
            {student_type?.entities?.map((e) => (
              <option key={e.id} value={e.id as string}>
                {e.type}
              </option>
            ))}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='student_type_id' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Class</label>

          <Field
            as='select'
            name='enrollment[class_id]'
            className='form-select form-select-lg form-select-solid'
            onChange={(e: any) => {
              formik.setFieldValue('enrollment[class_id]', e.target.value)
              setClassId(e.target.value)
              formik.values.enrollment.section_id = undefined
            }}
          >
            <option></option>
            {_class?.entities?.map((e) => (
              <option key={e.id} value={e.id as number}>
                {e.name}
              </option>
            ))}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='enrollment[class_id]' />
          </div>
        </div>
        {!isExternal && (
          <div className='mb-5 fv-row col-lg-4'>
            <label className='form-label mb-3 required'>Section</label>

            <Field
              as='select'
              name='enrollment[section_id]'
              className='form-select form-select-lg form-select-solid'
            >
              <option></option>
              {section?.entities?.map((e) => (
                <option key={e.id} value={e.id as number}>
                  {e.name}
                </option>
              ))}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='enrollment[section_id]' />
            </div>
          </div>
        )}
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Group</label>

          <Field as='select' name='group' className='form-select form-select-lg form-select-solid'>
            <option></option>
            <option value='science'>Science</option>
            <option value='art'>Art</option>
            <option value='commerce'>Commerce</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='group' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Previous School</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='previous_school'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='previous_school' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Previous Class</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='previous_class'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='previous_class' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>
            Transfer Certificate{' '}
            <small>
              <i>max. size: 2mb</i>
            </small>
          </label>

          <Field
            type='text'
            component={FileUpload}
            className='form-control form-control-lg form-control-solid'
            name='transfer_certificate'
            accept='.pdf, .doc, .docx, .ppt, .pptx'
            limit={2048}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='transfer_certificate' />
          </div>
        </div>
        {formik.values.id && (
          <div className='mb-5 fv-row col-lg-4'>
            <label className='form-label mb-3'>Status</label>

            <Field
              as='select'
              name='status'
              className='form-select form-select-lg form-select-solid'
            >
              <option></option>
              <option value='regular'>Regular</option>
              <option value='drop'>Drop</option>
              <option value='transfer'>Transfer</option>
              <option value='passed'>Passed</option>
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='status' />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {AdmissionInfo}
