import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {GetUser, ImageUpload} from '../../../../../../../../../_component/CustomInput'

const FatherInfo: FC<{isExternal: boolean | undefined}> = ({isExternal}) => {
  return (
    <div className='w-100'>
      <div className='row'>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Father Email</label>
          {isExternal ? (
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='father[email]'
            />
          ) : (
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='father[email]'
              component={GetUser}
            />
          )}
          <div className='text-danger mt-2'>
            <ErrorMessage name='father[email]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Father First Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='father[first_name]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='father[first_name]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Father Last Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='father[last_name]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='father[last_name]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Father Phone</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='father[phone]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='father[phone]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Father Highest Education</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='father[highest_education]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='father[highest_education]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Father Profession</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='father[profession]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='father[profession]' />
          </div>
        </div>
        <div className='fv-row mb-7 col-lg-4'>
          {/* begin::Label */}
          <label className='d-block form-label mb-3'>Father Image</label>
          {/* end::Label */}

          {/* begin::Image input */}
          <Field
            type='text'
            component={ImageUpload}
            className='form-control form-control-lg form-control-solid'
            name='father[image]'
          />
          {/* end::Image input */}

          {/* begin::Hint */}
          {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
          {/* end::Hint */}
        </div>
      </div>
    </div>
  )
}

export {FatherInfo}
