import {ID, Response} from '../../../../../../_metronic/helpers'
import {_Class} from '../../../class/classs-list/core/_models'
import {Section} from '../../../section/sections-list/core/_models'
import {Student} from '../../../student/students-list/core/_models'
export type Enrollment = {
  id?: ID
  roll_no?: string
  status?: boolean
  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
  created_by?: string
  updated_by?: string
  school_id?: string
  student_id?: ID
  class_id?: ID
  section_id?: ID
  academic_year_id?: ID
  class?: _Class
  section?: Section
  student?: Student
}

export type EnrollmentsQueryResponse = Response<Array<Enrollment>>

export const initialEnrollment: Enrollment = {}
