import {FC, useState, useEffect, createContext, useContext, useRef} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, SchoolModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {getSchool} from './_requests'
import {getMyPrivileges} from '../../apps/privilege/privileges-list/core/_requests'
import {Privilege} from '../../apps/privilege/privileges-list/core/_models'

type AuthContextProps = {
  auth: AuthModel | undefined
  school: SchoolModel | undefined
  privilege: Privilege[] | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  saveSchool: (school: SchoolModel | undefined) => void
  savePrivilege: (privilege: Privilege[] | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: (currentUser: UserModel | undefined) => void
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  school: authHelper.getSchool(),
  privilege: authHelper.getPrivilege(),
  saveAuth: () => {},
  saveSchool: () => {},
  savePrivilege: () => {},
  currentUser: authHelper.getCurrentUser(),
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [school, setSchool] = useState<SchoolModel | undefined>(authHelper.getSchool())
  const [privilege, setPrivilege] = useState<Privilege[] | undefined>(authHelper.getPrivilege())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(authHelper.getCurrentUser())
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }
  const saveSchool = (school: SchoolModel | undefined) => {
    setSchool(school)
    if (school) {
      authHelper.setSchool(school)
    } else {
      authHelper.removeSchool()
    }
  }
  const savePrivilege = (privilege: Privilege[] | undefined) => {
    setPrivilege(privilege)
    if (privilege) {
      authHelper.setPrivilege(privilege)
    } else {
      authHelper.removePrivilege()
    }
  }
  const saveCurrentUser = (currentUser: UserModel | undefined) => {
    setCurrentUser(currentUser)
    if (currentUser) {
      authHelper.setCurrentUser(currentUser)
    } else {
      authHelper.removeCurrentUser()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    saveCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser: saveCurrentUser,
        logout,
        school,
        saveSchool,
        privilege,
        savePrivilege,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, school, saveSchool, privilege, savePrivilege} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestPrivilege = async () => {
      try {
        if (!didRequest.current) {
          const data = await getMyPrivileges()
          if (data) {
            savePrivilege(data.entities)
          }
        }
      } catch (error) {
        savePrivilege(undefined)
      }

      return () => (didRequest.current = true)
    }
    const requestSchool = async () => {
      try {
        if (!didRequest.current) {
          const data = await getSchool()
          if (data) {
            saveSchool(data)
          }
        }
      } catch (error) {
        saveSchool(undefined)
      }

      return () => (didRequest.current = true)
    }
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          if (!privilege) {
            await requestPrivilege()
            const data = await getUserByToken()
            if (data) {
              setCurrentUser(data)
            }
          } else {
            const data = await getUserByToken()
            if (data) {
              setCurrentUser(data)
            }
            requestPrivilege()
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (school && school.name) {
      if (auth && auth.auth_token) {
        requestUser()
      } else {
        logout()
        setShowSplashScreen(false)
      }
      requestSchool()
    } else {
      requestSchool().then(() => {
        if (auth && auth.auth_token) {
          requestUser()
        } else {
          setShowSplashScreen(false)
          logout()
        }
      })
    }
    // eslint-disable-next-line
  }, [])
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
