import {FC, useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import {ImageInputComponent} from '../_metronic/assets/ts/components'
import {toAbsoluteUrl} from '../_metronic/helpers'
import AsyncCreatableSelect from 'react-select/async-creatable'
import {useQuery} from '@tanstack/react-query'
import {QUERIES} from '../_metronic/helpers'
import {getUsers} from '../app/modules/apps/user/users-list/core/_requests'
import {toast} from 'react-toastify'
import {useThemeMode} from '../_metronic/partials'
import {fileSrc} from './fileUtils'
// export const blankImg = toAbsoluteUrl('/media/misc/image-dark.png')
// export const blankImg = toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')

type Props = {
  value: string | number | Date | readonly (string | number | Date)[] | undefined
  defaultValue: string | undefined
  inputRef: any
}
export const CustomInput: FC<Props> = ({value, defaultValue, inputRef, ...props}) => {
  return <input {...props} defaultValue={defaultValue} ref={inputRef} />
}

export const DatePicker: FC<any> = ({field, form, ...props}) => {
  return (
    <Flatpickr
      onChange={([date]) => form.setFieldValue(field.name, date)}
      value={field.value}
      {...props}
      render={({defaultValue, value, ...props}, ref) => {
        return <CustomInput defaultValue={defaultValue} inputRef={ref} value={value} {...props} />
      }}
    />
  )
}

export const FileUpload: FC<any> = ({field, form, ...props}) => {
  const [error, setError] = useState<string | undefined>()
  return (
    <>
      <input
        {...props}
        type='file'
        onChange={(e) => {
          if (e.target.files) {
            if (props.limit && e.target.files[0].size > (props.limit || 120) * 1024) {
              toast.error(
                `File too large!! max size is ${
                  props.limit >= 1024 ? (props.limit || 120) / 1024 : props.limit || 120
                }${props.limit >= 1024 ? 'mb' : 'kb'}`
              )
              setError(
                `File too large!! max size is ${
                  props.limit >= 1024 ? (props.limit || 120) / 1024 : props.limit || 120
                }${props.limit >= 1024 ? 'mb' : 'kb'}`
              )
            } else {
              form.setFieldValue(
                field.name.replaceAll('[', '_').replaceAll(']', '') + '_to_upload',
                e.target.files[0]
              )
              setError(undefined)
            }
          }
        }}
      ></input>
      {error && <div className='text-danger mt-2'>{error}</div>}
      {field.value && (
        <a target='_blank' href={fileSrc(field.value)} rel='noreferrer' download>
          View/Download
        </a>
      )}
    </>
  )
}

export const ImageUpload: FC<any> = ({field, form, ...props}) => {
  const {mode} = useThemeMode()
  const blankImg =
    props.blankImg ||
    toAbsoluteUrl(`/media/svg/files/blank-image${mode === 'dark' ? '-dark' : ''}.svg`)
  // const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [error, setError] = useState<string | undefined>()
  useEffect(() => {
    ImageInputComponent.createInsance(`#${field.name.replaceAll('[', '_').replaceAll(']', '')}`)
  }, [field.name])
  const addImageProcess = (src: string): Promise<{height: number; width: number}> => {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve({height: img.height, width: img.width})
      img.onerror = reject
      img.src = src
    })
  }
  const uploadFile = async (file: File) => {
    let err = ''
    if (file.size > (props.limit || 120) * 1024) {
      err += `File too large!! max size is ${
        props.limit >= 1024 ? (props.limit || 120) / 1024 : props.limit || 120
      }${props.limit >= 1024 ? 'mb' : 'kb'} `
    }
    let image = document.getElementById('image-display') as any
    if (image !== null) {
      let img = new Image()
      img.src = URL.createObjectURL(file)
      const dimension = await addImageProcess(img.src)
      if (props.maxWidth && dimension.width > Number(props.maxWidth))
        err += `Maximum width of ${props.maxWidth} `
      if (props.maxHeight && dimension.height > Number(props.maxHeight))
        err += `Maximum height of ${props.maxHeight} `
      if (props.uploadWidth && dimension.width !== Number(props.uploadWidth))
        err += `Width must be ${props.uploadWidth} `
      if (props.uploadHeight && dimension.height !== Number(props.uploadHeight))
        err += `Height must be ${props.uploadHeight} `
      URL.revokeObjectURL(img.src)
    }
    if (err) {
      toast.error(err)
      setError(err)
      setTimeout(function () {
        document.getElementById('custom_remove')?.click()
      }, 100)
    } else {
      setError(undefined)
      form.setFieldValue(field.name.replaceAll('[', '_').replaceAll(']', '') + '_to_upload', file)
    }
  }
  return (
    <>
      <img id='image-display' style={{display: 'none'}} alt='dimension checker' />
      <div
        id={field.name.replaceAll('[', '_').replaceAll(']', '')}
        className='image-input image-input-outline'
        data-kt-image-input='true'
        style={{
          backgroundImage: `url('${blankImg}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      >
        {/* begin::Preview existing avatar */}
        <div
          className={`image-input-wrapper w-${props.width || '125'}px h-${props.height || '125'}px`}
          style={{
            backgroundImage: `url('${field.value ? fileSrc(field.value) : blankImg}')`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        ></div>
        {/* end::Preview existing avatar */}

        {/* begin::Label */}
        <label
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title='Change avatar'
        >
          <i className='bi bi-pencil-fill fs-7'></i>

          <input
            type='file'
            onChange={(e) => {
              if (e.target.files) {
                uploadFile(e.target.files[0])
              }
            }}
            name={field.name}
            accept='.png, .jpg, .jpeg'
          />
          <input type='hidden' name='avatar_remove' />
        </label>
        {/* end::Label */}

        {/* begin::Cancel */}
        <span
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='cancel'
          data-bs-toggle='tooltip'
          title='Cancel avatar'
          id='custom_cancel'
        >
          <i className='bi bi-x fs-2'></i>
        </span>
        {/* end::Cancel */}

        {/* begin::Remove */}
        <span
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='remove'
          data-bs-toggle='tooltip'
          title='Remove avatar'
          id='custom_remove'
        >
          <i className='bi bi-x fs-2'></i>
        </span>
        {/* end::Remove */}
      </div>
      {error && <div className='text-danger mt-2'>{error}</div>}
    </>
  )
}

export const GetUser: FC<any> = ({field, form, ...props}) => {
  const validateEmail = (email: string) => {
    return email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }
  const [search, setSearch] = useState('')
  const {refetch, data: user} = useQuery(
    [`${QUERIES.USERS_LIST}-search-${search}}`],
    () => {
      return getUsers(`search=${search}`)
    },
    {cacheTime: Infinity, keepPreviousData: true, refetchOnWindowFocus: false, enabled: false}
  )
  useEffect(() => {
    if (validateEmail(search) !== null) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const loadOptions = (inputValue: string, callback: (options: any) => void) => {
    if (validateEmail(inputValue) !== null) {
      setSearch(inputValue)
      callback(
        user?.entities?.map((e) => {
          return {
            value: e.id,
            label: `${e.first_name} ${e.last_name} (${e.email})`,
            ...e,
          }
        })
      )
    }
  }
  return (
    <AsyncCreatableSelect
      className='basic-single'
      classNamePrefix='select'
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      value={field.value ? {label: field.value, value: field.value} : ''}
      onChange={(e: any) => {
        if (e.id) {
          form.setFieldValue(field.name.replace('[email]', '_id'), e.id)
          form.setFieldValue(field.name.replace('email', 'id'), e.id)
          e.first_name &&
            form.setFieldValue(field.name.replace('email', 'first_name'), e.first_name)
          e.last_name && form.setFieldValue(field.name.replace('email', 'last_name'), e.last_name)
          e.other_name &&
            form.setFieldValue(field.name.replace('email', 'other_name'), e.other_name)
          e.email && form.setFieldValue(field.name, e.email)
          e.phone && form.setFieldValue(field.name.replace('email', 'phone'), e.phone)
          e.gender && form.setFieldValue(field.name.replace('email', 'gender'), e.gender)
          e.birthday && form.setFieldValue(field.name.replace('email', 'birthday'), e.birthday)
          e.blood_group &&
            form.setFieldValue(field.name.replace('email', 'blood_group'), e.blood_group)
          e.religion && form.setFieldValue(field.name.replace('email', 'religion'), e.religion)
          e.caste && form.setFieldValue(field.name.replace('email', 'caste'), e.caste)
          e.national_id &&
            form.setFieldValue(field.name.replace('email', 'national_id'), e.national_id)
          e.image && form.setFieldValue(field.name.replace('email', 'image'), e.image)
          e.address1 && form.setFieldValue(field.name.replace('email', 'address1'), e.address1)
          e.address2 && form.setFieldValue(field.name.replace('email', 'address2'), e.address2)
          e.highest_education &&
            form.setFieldValue(
              field.name.replace('email', 'highest_education'),
              e.highest_education
            )
          e.profession &&
            form.setFieldValue(field.name.replace('email', 'profession'), e.profession)
        } else {
          form.setFieldValue(field.name.replace('[email]', '_id'), undefined)
          form.setFieldValue(field.name, e.value)
        }
      }}
    />
  )
}

export const Switch: FC<any> = ({field, form, ...props}) => {
  return (
    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        defaultChecked={field.value}
        onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
      />
    </div>
  )
}
