import React, {FC} from 'react'
import {Field, ErrorMessage, FormikValues} from 'formik'
import {GetUser, ImageUpload, Switch} from '../../../../../../../../../_component/CustomInput'

const GuardianInfo: FC<FormikValues> = ({formik, isExternal}) => {
  return (
    <div className='w-100'>
      <div className='row'>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Guardian Email</label>
          {isExternal ? (
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='guardian[email]'
            />
          ) : (
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='guardian[email]'
              component={GetUser}
            />
          )}
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[email]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Guardian First Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='guardian[first_name]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[first_name]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Guardian Last Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='guardian[last_name]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[last_name]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Guardian Phone</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='guardian[phone]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[phone]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Relationship With Student</label>

          <Field
            as='select'
            name='relation_with_guardian'
            className='form-select form-select-lg form-select-solid'
          >
            <option></option>
            <option value='father'>Father</option>
            <option value='mother'>Mother</option>
            <option value='other'>Other</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='relation_with_guardian' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>National Id</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='guardian[national_id]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[national_id]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Guardian Highest Education</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='guardian[highest_education]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[highest_education]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Guardian Profession</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='guardian[profession]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[profession]' />
          </div>
        </div>
        {!isExternal && (
          <div className='mb-5 fv-row col-lg-4'>
            <label className='form-label mb-3'>Activate Login Account</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='guardian[status]'
              component={Switch}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='guardian[status]' />
            </div>
          </div>
        )}
        <div className='fv-row mb-7 col-lg-4'>
          {/* begin::Label */}
          <label className='d-block form-label mb-3'>Guardian Image</label>
          {/* end::Label */}

          {/* begin::Image input */}
          <Field
            type='text'
            component={ImageUpload}
            className='form-control form-control-lg form-control-solid'
            name='guardian[image]'
          />
          {/* end::Image input */}

          {/* begin::Hint */}
          {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
          {/* end::Hint */}
        </div>
        <div className='col-lg-12 fv-row'>
          <div className='d-flex align-items-center mt-3'>
            <label className='form-check form-check-inline form-check-solid me-5 form-label'>
              <input
                className='form-check-input'
                type='checkbox'
                defaultChecked={false}
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue('guardian[address1]', formik.values.account.user.address1)
                    formik.setFieldValue('guardian[address2]', formik.values.account.user.address2)
                  } else {
                    formik.setFieldValue('guardian[address1]', '')
                    formik.setFieldValue('guardian[address2]', '')
                  }
                }}
              />
              Same as student address?
            </label>
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-6'>
          <label className='form-label mb-3 required'>Guardian Present Address</label>

          <Field
            type='text'
            as='textarea'
            className='form-control form-control-lg form-control-solid'
            name='guardian[address1]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[address1]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-6'>
          <label className='form-label mb-3'>Guardian Permanent Address</label>

          <Field
            type='text'
            as='textarea'
            className='form-control form-control-lg form-control-solid'
            name='guardian[address2]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='guardian[address2]' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {GuardianInfo}
