import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const SUMMARY_URL = `${API_URL}/summary`

export const getStudentCountByClass = (): Promise<{name: string; count: number}[]> => {
  return axios.get(`${SUMMARY_URL}/get_student_count_by_class`)
}

export const getAccountCountByRole = (): Promise<{name: string; count: number}[]> => {
  return axios.get(`${SUMMARY_URL}/get_account_count_by_role`)
}
