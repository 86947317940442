import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Section, SectionsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const SECTION_URL = `${API_URL}/section`

const getSections = (query: string): Promise<SectionsQueryResponse> => {
  return axios.get(`${SECTION_URL}?${query}`)
}

const getSectionById = (id: ID): Promise<Section | undefined> => {
  return axios.get(`${SECTION_URL}/${id}`)
}

const createSection = (section: Section): Promise<Section | undefined> => {
  return axios.post(SECTION_URL, section)
}

const updateSection = (section: Section): Promise<Section | undefined> => {
  return axios.patch(`${SECTION_URL}`, section)
}

const deleteSection = (sectionId: ID): Promise<void> => {
  return axios.delete(`${SECTION_URL}/${sectionId}`)
}

const deleteSelectedSections = async (sectionIds: Array<ID>): Promise<void> => {
  const requests = sectionIds.map((id) => axios.delete(`${SECTION_URL}/${id}`))
  await axios.all(requests)
}

export {
  getSections,
  deleteSection,
  deleteSelectedSections,
  getSectionById,
  createSection,
  updateSection,
}
