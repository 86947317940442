/**
 * @app School Management System
 * @description Landing Page
 * @author Anthony Olasinde
 **/

import {useQuery} from '@tanstack/react-query'
import dayjs from 'dayjs'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {truncateString} from '../../../_component/CustomFunctions'
import {QUERIES} from '../../../_metronic/helpers'
import {getAbouts} from '../../modules/apps/about/abouts-list/core/_requests'
import {getEmployees} from '../../modules/apps/employee/employees-list/core/_requests'
import {News} from '../../modules/apps/news/news-list/core/_models'
import {getNews} from '../../modules/apps/news/news-list/core/_requests'
import {getSliders} from '../../modules/apps/slider/sliders-list/core/_requests'
import {useAuth} from '../../modules/auth'
import {Footer} from '../nav/Footer'
import {Header} from '../nav/Header'
import {fileSrc} from '../../../_component/fileUtils'

export const LandingPage: FC = () => {
  const {school} = useAuth()

  const {data: sliders} = useQuery(
    [`${QUERIES.SLIDERS_LIST}`],
    () => {
      return getSliders(`sort_field=order&sort_order=desc&filter={"status":true}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  const {data: employees} = useQuery(
    [`${QUERIES.EMPLOYEES_LIST}`],
    () => {
      return getEmployees(`page_size=3&filter={"role_id":2,"is_view_on_web":true,"status":true}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  const {data: about} = useQuery(
    [`${QUERIES.ABOUTS_LIST}`],
    () => {
      return getAbouts()
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  const {data: announcement} = useQuery(
    [`${QUERIES.ANNOUNCEMENTS_LIST}`],
    () => {
      return getNews(`page_size=3&filter={"is_view_on_web":true,"status":true}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  return school ? (
    <>
      {/*begin::Header Section*/}
      <div className='mb-0' id='home'>
        {/*begin::Wrapper*/}
        <div
          className='bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg'
          style={{backgroundImage: 'url(/media/svg/illustrations/landing.svg)'}}
        >
          {/*begin::Header*/}
          <Header />
          {/*end::Header*/}
          <div id='carouselExampleCaptions' className='carousel slide' data-bs-ride='carousel'>
            <div className='carousel-indicators'>
              {sliders?.entities?.map((slider, i) => (
                <button
                  key={i}
                  type='button'
                  data-bs-target='#carouselExampleCaptions'
                  data-bs-slide-to={`${i}`}
                  className={i === 0 ? 'active' : ''}
                  aria-current={i === 0 ? 'true' : 'false'}
                  aria-label={`Slide ${i + 1}`}
                ></button>
              ))}
            </div>
            <div className='carousel-inner'>
              {sliders?.entities?.map((slider, i) => (
                <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                  <div className='text-center px-5 pt-5 pt-lg-10 px-lg-5'>
                    <img
                      src={fileSrc(slider.image)}
                      className='card-rounded shadow mw-100'
                      alt=''
                    />
                    <div className='carousel-caption d-none d-md-block'>
                      <h1 className='text-light'>{slider.title}</h1>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: truncateString(slider.description, 60) || '',
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              className='btn btn-active-color-primary carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleCaptions'
              data-bs-slide='prev'
            >
              <span className='svg-icon svg-icon-3x'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              {/* <span className='carousel-control-prev-icon' aria-hidden='true'></span> */}
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='btn btn-active-color-primary carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleCaptions'
              data-bs-slide='next'
            >
              <span className='svg-icon svg-icon-3x'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              {/* <span className='carousel-control-next-icon' aria-hidden='true'></span> */}
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
          {/*begin::Landing hero*/}
          <div className='d-flex flex-column flex-center w-100 min-h-350px min-h-lg-350px px-9'>
            {/*begin::Heading*/}
            <div className='text-center py-5 py-lg-5'>
              {/*begin::Title*/}
              <h1 className='text-white lh-base fw-bold fs-3x fs-lg-3x mb-5'>
                Welcome to
                <br />
                <span
                  style={{
                    background: 'linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  <span id='kt_landing_hero_text'>{school.name}</span>
                </span>
              </h1>
              {/*end::Title*/}
              {/*begin::Action*/}
              <Link to='/web/admission' className='btn btn-primary'>
                Enrol Now
              </Link>
              {/*end::Action*/}
            </div>
            {/*end::Heading*/}
          </div>
          {/*end::Landing hero*/}
        </div>
        {/*end::Wrapper*/}
        {/*begin::Curve bottom*/}
        <div className='landing-curve landing-dark-color mb-10 mb-lg-20'>
          <svg viewBox='15 12 1470 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z'
              fill='currentColor'
            ></path>
          </svg>
        </div>
        {/*end::Curve bottom*/}
      </div>
      {/*end::Header Section*/}
      {/*begin::How It Works Section*/}
      <div className='mb-n10 mb-lg-n20 z-index-2' id='admin'>
        {/*begin::Container*/}
        <div className='container mb-md-20'>
          {/*begin::Heading*/}
          <div className='text-center mb-17'>
            {/*begin::Title*/}
            <h3
              className='fs-2hx text-dark mb-5'
              id='how-it-works'
              data-kt-scroll-offset='{default: 100, lg: 150}'
            >
              Administrators
            </h3>
            {/*end::Title*/}
          </div>
          {/*end::Heading*/}
          {/*begin::Row*/}
          <div className='container'>
            {/*begin::Row*/}
            <div className='row w-100 gy-10 mb-md-20'>
              {/*begin::Col*/}
              {employees?.entities?.map((employee, i) => (
                <div key={i} className='col-md-4 px-5'>
                  {/*begin::Story*/}
                  <div className='text-center mb-10 mb-md-0'>
                    {/*begin::Illustration*/}
                    <div
                      className='octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center'
                      style={{
                        backgroundImage: `url(${
                          employee.account?.user?.image
                            ? fileSrc(employee.account?.user?.image)
                            : '/media/avatars/blank.png'
                        })`,
                      }}
                    ></div>
                    {/*end::Illustration*/}
                    <div className='mb-0'>
                      {/*begin::Name*/}
                      <a href='#!' className='text-dark fw-bold text-hover-primary fs-3'>
                        {employee.account?.user?.first_name} {employee.account?.user?.last_name}
                      </a>
                      {/*end::Name*/}
                      {/*begin::Position*/}
                      <div className='text-muted fs-6 fw-semibold mt-1'>
                        {employee.designation?.name}
                      </div>
                      {/*begin::Position*/}
                    </div>
                  </div>
                  {/*end::Story*/}
                </div>
              ))}
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Product slider*/}
            {/*end::Product slider*/}
          </div>
          {/*end::Row*/}
          {/*begin::Product slider*/}
          {/*end::Product slider*/}
        </div>
        {/*begin::Container*/}
        <div className='container' id='about'>
          {/*begin::Card*/}
          <div
            className='card'
            style={{filter: 'drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))'}}
          >
            {/*begin::Card body*/}
            <div className='card-body p-lg-20'>
              {/*begin::Heading*/}
              <div className='text-center mb-5 mb-lg-10'>
                {/*begin::Title*/}
                <h3
                  className='fs-2hx text-dark mb-5'
                  id='portfolio'
                  data-kt-scroll-offset='{default: 100, lg: 250}'
                >
                  About Us
                </h3>
                {/*end::Title*/}
              </div>
              {/*end::Heading*/}
              {/*begin::Row*/}
              <div className='row g-10'>
                {/*begin::Col*/}
                <div className='col-lg-6'>
                  {/*begin::Item*/}
                  <a
                    className='d-block card-rounded overlay h-lg-100'
                    data-fslightbox='lightbox-projects'
                    href={about?.about_image ? fileSrc(about?.about_image) : ''}
                  >
                    {/*begin::Image*/}
                    <div
                      className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px'
                      style={{
                        backgroundImage: `url(${
                          about?.about_image ? fileSrc(about?.about_image) : ''
                        }`,
                      }}
                    ></div>
                    {/*end::Image*/}
                    {/*begin::Action*/}
                    <div className='overlay-layer card-rounded bg-dark bg-opacity-25'>
                      <i className='bi bi-eye-fill fs-3x text-white'></i>
                    </div>
                    {/*end::Action*/}
                  </a>
                  {/*end::Item*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div
                  className='col-lg-6'
                  dangerouslySetInnerHTML={{__html: about?.about_text || ''}}
                ></div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::How It Works Section*/}
      {/*begin::Statistics Section*/}
      <div className='mt-sm-n10'>
        {/*begin::Curve top*/}
        <div className='landing-curve landing-dark-color'>
          <svg viewBox='15 -1 1470 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z'
              fill='currentColor'
            ></path>
          </svg>
        </div>
        {/*end::Curve top*/}
        {/*begin::Wrapper*/}
        <div className='pb-15 pt-18 landing-dark-bg'>
          {/*begin::Container*/}
          <div className='container'>
            {/*begin::Heading*/}
            <div
              className='text-center mt-15 mb-18'
              id='achievements'
              data-kt-scroll-offset='{default: 100, lg: 150}'
            >
              {/*begin::Title*/}
              <h3 className='fs-2hx text-white fw-bold mb-5'>Our Facilities</h3>
              {/*end::Title*/}
              {/*begin::Description*/}
              {/* <div className='fs-5 text-gray-700 fw-bold'>
                Save thousands to millions of bucks by using single tool
                <br />
                for different amazing and great useful admin
              </div> */}
              {/*end::Description*/}
            </div>
            {/*end::Heading*/}
            {/*begin::Statistics*/}
            <div className='d-flex flex-center'>
              {/*begin::Items*/}
              <div className='d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px'>
                {/*begin::Item*/}
                <div
                  className='d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain'
                  style={{backgroundImage: "url('/media/svg/misc/octagon.svg')"}}
                >
                  {/*begin::Symbol*/}
                  {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                  <span className='svg-icon svg-icon-2tx svg-icon-white mb-3'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        opacity='0.3'
                        d='M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z'
                        fill='currentColor'
                      />
                      <path
                        d='M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Symbol*/}
                  {/*begin::Info*/}
                  <div className='mb-0'>
                    {/*begin::Value*/}
                    <div className='fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center'>
                      Library
                    </div>
                    {/*end::Value*/}
                    {/*begin::Label*/}
                    {/* <span className='text-gray-600 fw-semibold fs-5 lh-0'>Known Companies</span> */}
                    {/*end::Label*/}
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div
                  className='d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain'
                  style={{backgroundImage: "url('/media/svg/misc/octagon.svg')"}}
                >
                  {/*begin::Symbol*/}
                  {/*begin::Svg Icon | path: icons/duotune/graphs/gra008.svg*/}
                  <span className='svg-icon svg-icon-2tx svg-icon-white mb-3'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z'
                        fill='currentColor'
                      />
                      <path
                        opacity='0.3'
                        d='M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z'
                        fill='currentColor'
                      />
                      <path opacity='0.3' d='M15 17H9V20H15V17Z' fill='currentColor' />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Symbol*/}
                  {/*begin::Info*/}
                  <div className='mb-0'>
                    {/*begin::Value*/}
                    <div className='fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center'>
                      ICT Center
                    </div>
                    {/*end::Value*/}
                    {/*begin::Label*/}
                    {/* <span className='text-gray-600 fw-semibold fs-5 lh-0'>Statistic Reports</span> */}
                    {/*end::Label*/}
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div
                  className='d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain'
                  style={{backgroundImage: "url('/media/svg/misc/octagon.svg')"}}
                >
                  {/*begin::Symbol*/}
                  {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg*/}
                  <span className='svg-icon svg-icon-2tx svg-icon-white mb-3'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z'
                        fill='currentColor'
                      />
                      <path
                        opacity='0.3'
                        d='M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                  {/*end::Symbol*/}
                  {/*begin::Info*/}
                  <div className='mb-0'>
                    {/*begin::Value*/}
                    <div className='fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center'>
                      Hostel
                    </div>
                    {/*end::Value*/}
                    {/*begin::Label*/}
                    {/* <span className='text-gray-600 fw-semibold fs-5 lh-0'>Secure Payments</span> */}
                    {/*end::Label*/}
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::Item*/}
              </div>
              {/*end::Items*/}
            </div>
            {/*end::Statistics*/}
            {/*begin::Testimonial*/}
            {/* <div className='fs-2 fw-semibold text-muted text-center mb-3'>
              <span className='fs-1 lh-1 text-gray-700'>“</span>When you care about your topic,
              you’ll write about it in a
              <br />
              <span className='text-gray-700 me-1'>more powerful</span>, emotionally expressive way
              <span className='fs-1 lh-1 text-gray-700'>“</span>
            </div> */}
            {/*end::Testimonial*/}
            {/*begin::Author*/}
            {/* <div className='fs-2 fw-semibold text-muted text-center'>
              <a
                href='../../demo1/dist/account/security.html'
                className='link-primary fs-4 fw-bold'
              >
                Marcus Levy,
              </a>
              <span className='fs-4 fw-bold text-gray-600'>KeenThemes CEO</span>
            </div> */}
            {/*end::Author*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Wrapper*/}
        {/*begin::Curve bottom*/}
        <div className='landing-curve landing-dark-color'>
          <svg viewBox='15 12 1470 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z'
              fill='currentColor'
            ></path>
          </svg>
        </div>
        {/*end::Curve bottom*/}
      </div>
      {/*end::Statistics Section*/}
      {/*begin::Testimonials Section*/}
      <div className='mt-20 mb-n20 position-relative z-index-2'>
        {/*begin::Container*/}
        <div className='container'>
          {/*begin::Heading*/}
          <div className='text-center mb-17'>
            {/*begin::Title*/}
            <h3 className='fs-2hx text-dark mb-5'>Announcement</h3>
            {/*end::Title*/}
          </div>
          {/*end::Heading*/}
          {/*begin::Row*/}
          <div className='row g-lg-10 mb-10 mb-lg-20'>
            {/*begin::Col*/}
            {announcement?.entities?.map((entity: News, i: number) => (
              <div key={i} className='col-md-4'>
                {/*begin::Publications post*/}
                <div className='card-xl-stretch me-md-6'>
                  {/*begin::Overlay*/}
                  <a
                    className='d-block overlay mb-4'
                    data-fslightbox='lightbox-hot-sales'
                    href={entity.image ? fileSrc(entity.image) : ''}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {/*begin::Image*/}
                    <div
                      className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded min-h-175px'
                      style={{
                        backgroundImage: `url(${entity.image ? fileSrc(entity.image) : ''})`,
                      }}
                    ></div>
                    {/*end::Image*/}
                    {/*begin::Action*/}
                    <div className='overlay-layer bg-dark card-rounded bg-opacity-25'>
                      <i className='bi bi-eye-fill fs-2x text-white'></i>
                    </div>
                    {/*end::Action*/}
                  </a>
                  {/*end::Overlay*/}
                  {/*begin::Body*/}
                  <div className='m-0'>
                    {/*begin::Title*/}
                    <Link
                      to={`/web/announcement/${entity.id}`}
                      className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'
                    >
                      {entity.title}
                    </Link>
                    {/*end::Title*/}
                    {/*begin::Text*/}
                    <div
                      className='fw-semibold fs-5 text-gray-600 text-dark mt-3'
                      dangerouslySetInnerHTML={{__html: truncateString(entity.description, 100)}}
                    ></div>
                    {/*end::Text*/}
                    <Link to={`/web/announcement/${entity.id}`}>Read more...</Link>
                    {/*begin::Content*/}
                    <div className='fs-6 fw-bold'>
                      {/*begin::Author*/}
                      <span
                        // href='../../demo1/dist/apps/projects/users.html'
                        className='text-gray-700 text-hover-primary'
                      >
                        {entity.account?.user?.name}
                      </span>
                      {/*end::Author*/}
                      {/*begin::Date*/}
                      <span className='text-muted'>
                        {' '}
                        on {dayjs(entity.created_at).format('MMM DD YYYY')}
                      </span>
                      {/*end::Date*/}
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Publications post*/}
              </div>
            ))}
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin::Highlight*/}
          <div
            className='d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13'
            style={{background: 'linear-gradient(90deg, #20AA3E 0%, #03A588 100%)'}}
          >
            {/*begin::Content*/}
            <div className='my-2 me-5'>
              {/*begin::Title*/}
              <div className='fs-1 fs-lg-2qx fw-bold text-white mb-2'>
                Enrol your Kids today,
                <span className='fw-normal'>For the best Education!</span>
              </div>
              {/*end::Title*/}
              {/*begin::Description*/}
              <div className='fs-6 fs-lg-5 text-white fw-semibold opacity-75'>
                Join over 100 parent giving their kids the best
              </div>
              {/*end::Description*/}
            </div>
            {/*end::Content*/}
            {/*begin::Link*/}
            <Link
              to='/web/admission'
              className='btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2'
            >
              Enrol Now
            </Link>
            {/*end::Link*/}
          </div>
          {/*end::Highlight*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Testimonials Section*/}
      <Footer />
    </>
  ) : (
    <h2 className='m-auto text-danger'>School Data Not Found</h2>
  )
}
