import {FormikValues} from 'formik'
import {useNavigate} from 'react-router-dom'
import {FC} from 'react'
import {createAdmission} from '../../modules/apps/admission/admissions-list/core/_requests'
import {initialStudent, Student} from '../../modules/apps/student/students-list/core/_models'
import {StudentEditModalForm} from '../../modules/apps/student/students-list/student-edit-modal/StudentEditModalForm'

export const AdmissionPage: FC = () => {
  const navigate = useNavigate()
  const submit = async (values: Student, actions: FormikValues) => {
    try {
      await createAdmission(values)
      alert(
        'Your application has been submitted successfully. You will be contacted shortly through the provided contact info.'
      )
      navigate('/')
    } catch (ex) {
      console.error(ex)
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid mt-5'>
      {/*begin::Content container*/}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='card'>
          <div className='card-header'>
            <h2 className='card-title m-auto'>Application Form</h2>
          </div>
          {/*begin::Admission*/}
          <StudentEditModalForm
            isStudentLoading={false}
            student={initialStudent}
            submit={submit}
            isExternal={true}
          />
          {/*end::Admission*/}
        </div>
      </div>
      {/*end::Content container*/}
    </div>
  )
}
