import {useQuery} from '@tanstack/react-query'
import dayjs from 'dayjs'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {truncateString} from '../../../_component/CustomFunctions'
import {QUERIES} from '../../../_metronic/helpers'
import {News} from '../../modules/apps/news/news-list/core/_models'
import {getNews} from '../../modules/apps/news/news-list/core/_requests'
import {fileSrc} from '../../../_component/fileUtils'

export const AnnouncementPage: FC = () => {
  const {data: announcements} = useQuery(
    [`${QUERIES.ANNOUNCEMENTS_LIST}`],
    () => {
      return getNews(`page_size=24&filter={"is_view_on_web":true,"status":true}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/*begin::Content container*/}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/*begin::About card*/}
        <div className='card'>
          {/*begin::Body*/}
          <div className='card-body p-lg-17'>
            {/*begin::Statistics*/}
            <div className='card bg-light mb-18'>
              {/*begin::Body*/}
              <div className='card-body py-15'>
                {/*begin::Row*/}
                <div className='row g-10'>
                  {/*begin::Col*/}
                  {announcements?.entities?.map((entity: News) => (
                    <div className='col-md-4' key={entity.id}>
                      {/*begin::Publications post*/}
                      <div className='card-xl-stretch me-md-6'>
                        {/*begin::Overlay*/}
                        <a
                          className='d-block overlay mb-4'
                          data-fslightbox='lightbox-hot-sales'
                          href={entity.image ? fileSrc(entity.image) : ''}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {/*begin::Image*/}
                          <div
                            className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded min-h-175px'
                            style={{
                              backgroundImage: `url(${entity.image ? fileSrc(entity.image) : ''})`,
                            }}
                          ></div>
                          {/*end::Image*/}
                          {/*begin::Action*/}
                          <div className='overlay-layer bg-dark card-rounded bg-opacity-25'>
                            <i className='bi bi-eye-fill fs-2x text-white'></i>
                          </div>
                          {/*end::Action*/}
                        </a>
                        {/*end::Overlay*/}
                        {/*begin::Body*/}
                        <div className='m-0'>
                          {/*begin::Title*/}
                          <Link
                            to={`/web/announcement/${entity.id}`}
                            className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'
                          >
                            {entity.title}
                          </Link>
                          {/*end::Title*/}
                          {/*begin::Text*/}
                          <div
                            className='fw-semibold fs-5 text-gray-600 text-dark mt-3'
                            dangerouslySetInnerHTML={{
                              __html: truncateString(entity.description, 100),
                            }}
                          ></div>
                          {/*end::Text*/}
                          <Link to={`/web/announcement/${entity.id}`}>Read more...</Link>
                          {/*begin::Content*/}
                          <div className='fs-6 fw-bold'>
                            {/*begin::Author*/}
                            <span
                              // href='../../demo1/dist/apps/projects/users.html'
                              className='text-gray-700 text-hover-primary'
                            >
                              {entity.account?.user?.name}
                            </span>
                            {/*end::Author*/}
                            {/*begin::Date*/}
                            <span className='text-muted'>
                              {' '}
                              on {dayjs(entity.created_at).format('MMM DD YYYY')}
                            </span>
                            {/*end::Date*/}
                          </div>
                          {/*end::Content*/}
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Publications post*/}
                    </div>
                  ))}
                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Statistics*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::About card*/}
      </div>
      {/*end::Content container*/}
    </div>
  )
}
