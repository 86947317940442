import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${USER_URL}?${query}`)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios.get(`${USER_URL}/${id}`)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios.post(USER_URL, user)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios.patch(`${USER_URL}`, user)
}
const updateEmail = (user: User): Promise<User | undefined> => {
  return axios.patch(`${USER_URL}/email`, user)
}
const updatePassword = (user: User): Promise<User | undefined> => {
  return axios.patch(`${USER_URL}/password`, user)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`)
}

const deleteSelectedUsers = async (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  await axios.all(requests)
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  updateEmail,
  updatePassword,
}
