import axios from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {AuthModel, SchoolModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const SWITCH_ACCOUNT_URL = `${API_URL}/switch_account`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`

// Server should return AuthModel
export async function login(email: string, password: string, school_id?: ID) {
  const res = (await axios.post(LOGIN_URL, {
    email,
    password,
    school_id,
  })) as AuthModel
  return res
}

// Server should return AuthModel
export async function switchAccount(account_id: ID) {
  const res = (await axios.post(SWITCH_ACCOUNT_URL, {
    account_id,
  })) as AuthModel
  return res
}

export function refreshToken(refresh_token: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    refresh_token,
  })
}

// Server should return AuthModel
export async function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  const res = (await axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })) as AuthModel
  return res
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(
  email: string,
  school_id?: ID,
  reset_key?: number,
  password?: string
) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
    school_id,
    reset_key,
    password,
  })
}

export async function getUserByToken() {
  const res = (await axios.get(GET_USER_BY_ACCESSTOKEN_URL)) as UserModel
  return res
}

export const GET_SCHOOL = `${API_URL}/get_school`

export async function getSchool() {
  const res = (await axios.get(GET_SCHOOL)) as SchoolModel
  return res
}
