import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Gallery, GallerysQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GALLERY_URL = `${API_URL}/gallery`

const getGallerys = (query: string): Promise<GallerysQueryResponse> => {
  return axios.get(`${GALLERY_URL}?${query}`)
}

const getGalleryById = (id: ID): Promise<Gallery | undefined> => {
  return axios.get(`${GALLERY_URL}/${id}`)
}

const createGallery = (gallery: Gallery): Promise<Gallery | undefined> => {
  return axios.post(GALLERY_URL, gallery)
}

const updateGallery = (gallery: Gallery): Promise<Gallery | undefined> => {
  return axios.patch(`${GALLERY_URL}`, gallery)
}

const deleteGallery = (galleryId: ID): Promise<void> => {
  return axios.delete(`${GALLERY_URL}/${galleryId}`)
}

const deleteSelectedGallerys = async (galleryIds: Array<ID>): Promise<void> => {
  const requests = galleryIds.map((id) => axios.delete(`${GALLERY_URL}/${id}`))
  await axios.all(requests)
}

export {
  getGallerys,
  deleteGallery,
  deleteSelectedGallerys,
  getGalleryById,
  createGallery,
  updateGallery,
}
