import {AuthModel, SchoolModel, UserModel} from './_models'
import {toast} from 'react-toastify'
import {AxiosError, AxiosResponse} from 'axios'
// import {refreshToken} from './_requests'
import {Privilege} from '../../apps/privilege/privileges-list/core/_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const SCHOOL_LOCAL_STORAGE_KEY = 'kt-school-react-v'
const PRIVILEGE_LOCAL_STORAGE_KEY = 'kt-privilege-react-v'
const CURRENTUSER_LOCAL_STORAGE_KEY = 'kt-currentuser-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}
const getCurrentUser = (): UserModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(CURRENTUSER_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const user: UserModel = JSON.parse(lsValue) as UserModel
    if (user) {
      return user
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}
const getSchool = (): SchoolModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(SCHOOL_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const school: SchoolModel = JSON.parse(lsValue) as SchoolModel
    if (school) {
      return school
    }
  } catch (error) {
    console.error('SCHOOL LOCAL STORAGE PARSE ERROR', error)
  }
}
const getPrivilege = (): Privilege[] | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(PRIVILEGE_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const privilege: Privilege[] = JSON.parse(lsValue) as Privilege[]
    if (privilege) {
      return privilege
    }
  } catch (error) {
    console.error('PRIVILEGE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}
const setCurrentUser = (currentUser: UserModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(currentUser)
    localStorage.setItem(CURRENTUSER_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setSchool = (school: SchoolModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(school)
    localStorage.setItem(SCHOOL_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('SCHOOL LOCAL STORAGE SAVE ERROR', error)
  }
}

const setPrivilege = (privilege: Privilege[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(privilege)
    localStorage.setItem(PRIVILEGE_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('PRIVILEGE LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
const removeCurrentUser = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(CURRENTUSER_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
const removeSchool = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(SCHOOL_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('SCHOOL LOCAL STORAGE REMOVE ERROR', error)
  }
}
const removePrivilege = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(PRIVILEGE_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('PRIVILEGE LOCAL STORAGE REMOVE ERROR', error)
  }
}
export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string; school_id: string}}) => {
      const auth = getAuth()
      if (auth && auth.auth_token) config.headers.Authorization = `Bearer ${auth.auth_token}`

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      response.data?.message && toast.success(response.data.message)
      return response.data.data
    },
    async (error: AxiosError) => {
      const auth = getAuth()
      const originalRequest: any = error.config
      if (error?.response?.status === 403 && auth?.refresh_token && !originalRequest._retry) {
        try {
          originalRequest._retry = true
          removeAuth()
          removeCurrentUser()
          window.location.reload()
          // const splashScreen = document.getElementById('splash-screen')
          // if (splashScreen) splashScreen.style.display = ''
          // const response = await refreshToken(auth.refresh_token)
          // // if (splashScreen) splashScreen.style.display = 'none'
          // if (response.status === 200) {
          //   setAuth(response.data)
          //   return axios(originalRequest)
          // } else return Logout()
        } catch (error) {
          removeAuth()
          removeCurrentUser()
          window.location.reload()
        }
      } else if (error?.response?.status === 401) {
        removeAuth()
        removeCurrentUser()
        window.location.reload()
      } else {
        const data: any = error?.response?.data
        if (data?.message) toast.error(data.message)
        throw error
      }
    }
  )
}

export {
  getAuth,
  getCurrentUser,
  getSchool,
  getPrivilege,
  setAuth,
  setCurrentUser,
  setSchool,
  setPrivilege,
  removeAuth,
  removeCurrentUser,
  removeSchool,
  removePrivilege,
  AUTH_LOCAL_STORAGE_KEY,
  SCHOOL_LOCAL_STORAGE_KEY,
  PRIVILEGE_LOCAL_STORAGE_KEY,
}
