/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import {GetUser, ImageUpload, Switch} from '../../../../../../../../../_component/CustomInput'
import {User} from '../../../../../../user/users-list/core/_models'
import {ID} from '../../../../../../../../../_metronic/helpers'
import dayjs from 'dayjs'

type Props = {
  user: User
  id: ID
  isExternal?: boolean
}
const StudentInfo: FC<Props> = ({user, id, isExternal}) => {
  const [state, setState] = useState(false)
  return (
    <div className='w-100'>
      {!id && !isExternal && (
        <div className='mb-5 fv-row'>
          <div className='row mb-2' data-kt-buttons='true'>
            <div className='col-lg'>
              <input
                type='radio'
                className='btn-check'
                id='kt_account_team_size_select_1'
                onChange={() => setState(false)}
                checked={!state}
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                htmlFor='kt_account_team_size_select_1'
              >
                <span className='fw-bolder fs-3'>Create New User</span>
              </label>
            </div>

            <div className='col-lg'>
              <input
                type='radio'
                className='btn-check'
                id='kt_account_team_size_select_2'
                onChange={() => setState(true)}
                checked={state}
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                htmlFor='kt_account_team_size_select_2'
              >
                <span className='fw-bolder fs-3'>Search User By Email</span>
              </label>
            </div>
          </div>

          {/* <div className='form-text'>
          Search for exi
        </div> */}
        </div>
      )}

      <div className='row'>
        {state && (
          <div className='mb-5 fv-row col-lg-12'>
            <label className='form-label mb-3 required'>Search by Email</label>

            <Field
              type='email'
              className='form-control form-control-lg form-control-solid'
              name='account[user[email]]'
              component={GetUser}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='account[user[email]]' />
            </div>
          </div>
        )}
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Student First Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='account[user[first_name]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[first_name]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Student Last Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='account[user[last_name]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[last_name]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Student Other Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='account[user[other_name]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[other_name]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Student Gender</label>

          <Field
            as='select'
            name='account[user[gender]]'
            className='form-select form-select-lg form-select-solid'
          >
            <option></option>
            <option value='male'>Male</option>
            <option value='female'>Female</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[gender]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className={`form-label mb-3 ${isExternal ? 'required' : ''}`}>Student Email</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='account[user[email]]'
            required={isExternal ? true : false}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[email]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Student Phone Number</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='account[user[phone]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[phone]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Student Birthday</label>

          <Field
            type='date'
            className='form-control form-control-lg form-control-solid'
            name='account[user[birthday]]'
            value={user?.birthday ? dayjs(user?.birthday).format('YYYY-MM-DD') : ''}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[birthday]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Student Blood Group</label>

          <Field
            as='select'
            name='account[user[blood_group]]'
            className='form-select form-select-lg form-select-solid'
          >
            <option></option>
            <option value='A+'>A+</option>
            <option value='A-'>A-</option>
            <option value='B+'>B+</option>
            <option value='B-'>B-</option>
            <option value='O+'>O+</option>
            <option value='O-'>O-</option>
            <option value='AB+'>AB+</option>
            <option value='AB-'>AB-</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[blood_group]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Student Religion</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='account[user[religion]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[religion]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Student Caste</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='account[user[caste]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[caste]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Student National Id</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='account[user[national_id]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[national_id]]' />
          </div>
        </div>
        {!isExternal && (
          <div className='mb-5 fv-row col-lg-4'>
            <label className='form-label mb-3'>Activate Login Account</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='account[user[status]]'
              component={Switch}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='account[user[national_id]]' />
            </div>
          </div>
        )}
        <div className='fv-row mb-7 col-lg-4'>
          {/* begin::Label */}
          <label className='d-block form-label mb-3'>Student Passport</label>
          {/* end::Label */}

          {/* begin::Image input */}
          <Field
            type='text'
            component={ImageUpload}
            className='form-control form-control-lg form-control-solid'
            name='account[user[image]]'
          />
          {/* end::Image input */}

          {/* begin::Hint */}
          {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
          {/* end::Hint */}
        </div>
      </div>
      <div className='row'>
        <div className='mb-5 fv-row col-lg-6'>
          <label className='form-label mb-3 required'>Student Present Address</label>

          <Field
            type='text'
            as='textarea'
            className='form-control form-control-lg form-control-solid'
            name='account[user[address1]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[address1]]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-6'>
          <label className='form-label mb-3'>Student Permanent Address</label>

          <Field
            type='text'
            as='textarea'
            className='form-control form-control-lg form-control-solid'
            name='account[user[address2]]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='account[user[address2]]' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {StudentInfo}
