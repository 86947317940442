export const truncateString = (str: string, len: number) => {
  if (str) {
    if (str.length <= len) {
      return str
    }
    return str.slice(0, len) + '...'
  } else return ''
}

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

export const getTitle = (pathname: string) => {
  const title = pathname.split('/')
  return title[title.length - 1]
}
