/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate, Outlet, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {LandingPage} from '../pages/landing/Landing'
import {ContactPage} from '../pages/contact/Contact'
import {Footer} from '../pages/nav/Footer'
import {Header} from '../pages/nav/Header'
import {WithChildren} from '../../_metronic/helpers'
import {AdmissionPage} from '../pages/admission/Admission'
import {AnnouncementPage} from '../pages/announcement/Announcement'
import {AnnouncementDetailsPage} from '../pages/announcement/AnnouncementDetails'
import {GalleryPage} from '../pages/gallery/Gallery'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser, school} = useAuth()

  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            <Route path='school-not-found' element={<SchoolNotFound />} />
            {school?.enable_frontend ? (
              <Route path='/' element={<LandingPage />} />
            ) : (
              <Route path='/' element={<Navigate to='/auth' />} />
            )}
            {currentUser ? (
              <Route path='/*' element={<PrivateRoutes />} />
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
            )}
            <Route path='web/*' element={<FrontEnd />} />
            <Route path='*' element={<Navigate to='/error/404' />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export {AppRoutes}

const FrontEnd = () => {
  const {school} = useAuth()
  return (
    <Routes>
      {school ? (
        <Route element={<App1 />}>
          <Route path='contact-us' element={<ContactPage />} />
          {school.enable_online_admission ? (
            <Route path='admission' element={<AdmissionPage />} />
          ) : (
            <Route path='*' element={<Navigate to='/' />} />
          )}
          <Route path='announcement/:id' element={<AnnouncementDetailsPage />} />
          <Route path='announcement' element={<AnnouncementPage />} />
          <Route path='gallery/:id' element={<AnnouncementDetailsPage />} />
          <Route path='gallery' element={<GalleryPage />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Route>
      ) : (
        <Route path='*' element={<Navigate to='/school-not-found' />} />
      )}
    </Routes>
  )
}
const App1: FC<WithChildren> = ({children}) => {
  const {pathname} = useLocation()
  return (
    <>
      <div className='mb-0' id='home'>
        {/*begin::Wrapper*/}
        <div
          className='bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg'
          style={{backgroundImage: 'url(/media/svg/illustrations/landing.svg)'}}
          id='custom-header'
        >
          {/*begin::Header*/}
          <Header />
          {/*end::Header*/}
          <div className='text-center py-5'>
            {/*begin::Title*/}
            <h1 className='text-white lh-base fw-bold fs-3x fs-lg-3x mb-5'>
              <span
                style={{
                  background: 'linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                <span id='kt_landing_hero_text' className='text-capitalize'>
                  {pathname.split('/')[2]?.replace('-', ' ').toUpperCase()}
                </span>
              </span>
            </h1>
            {/*end::Title*/}
          </div>
        </div>
        {/*end::Wrapper*/}
        {/*begin::Curve bottom*/}
        <div className='landing-curve landing-dark-color mb-10 mb-lg-20'>
          <svg viewBox='15 12 1470 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z'
              fill='currentColor'
            ></path>
          </svg>
        </div>
        {/*end::Curve bottom*/}
      </div>
      <Outlet />
      <>{children}</>
      <Footer />
    </>
  )
}

const SchoolNotFound = () => <h2 className='m-auto text-danger'>School Data Not Found</h2>
