import {ID, Response} from '../../../../../../_metronic/helpers'
export type User = {
  id?: ID
  email?: string
  name?: string
  first_name?: string
  last_name?: string
  other_name?: string
  status?: boolean
  last_logged_in?: string
  phone?: string | null
  gender?: 'male' | 'female' | null
  birthday?: Date | null
  blood_group?: 'A+' | 'A-' | 'B+' | 'B-' | 'O+' | 'O-' | 'AB+' | 'AB-' | null
  religion?: string
  caste?: string
  national_id?: string
  city?: string
  state?: string
  country?: string
  image?: string
  image_to_upload?: File
  address1?: string
  address2?: string
  highest_education?: string
  profession?: string
  activate_account?: boolean
  facebook_url?: string
  twitter_url?: string
  linkedin_url?: string
  youtube_url?: string
  pinterest?: string
  instagram_url?: string
  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
  created_by?: string
  updated_by?: string
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  email: '',
  first_name: '',
  last_name: '',
  other_name: '',
  phone: '',
  gender: undefined,
  birthday: undefined,
  religion: '',
  caste: '',
  national_id: '',
  image: '',
  address1: '',
  address2: '',
  highest_education: '',
  profession: '',
}
