/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
// import {
//   MixedWidget2,
//   MixedWidget10,
//   MixedWidget11,
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget5,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
// } from '../../../_metronic/partials/widgets'
import {AccountStat} from './components/AccountStat'
import {Cal} from './components/Calendar'
import {StudentStat} from './components/StudentStat'
import {useAuth} from '../../modules/auth'

const DashboardPage: FC = () => {
  const {currentUser} = useAuth()
  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-8'>
          <Cal />
        </div>
        {currentUser && currentUser?.role_id <= 3 && (
          <div className='col-xxl-4'>
            <StudentStat className='mb-5' />
            <AccountStat className='mb-5' />
          </div>
        )}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
