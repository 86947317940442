import React, {FC, useEffect, useRef, useState} from 'react'
import {StudentInfo} from './wizards/components/steps/StudentInfo'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {StepperComponent} from '../../../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {createStudentSchemas, Student} from '../core/_models'
import {GuardianInfo} from './wizards/components/steps/GuardianInfo'
import {FatherInfo} from './wizards/components/steps/FatherInfo'
import {MotherInfo} from './wizards/components/steps/MotherInfo'
import {AdmissionInfo} from './wizards/components/steps/AdmissionInfo'
import {uploadFile} from '../../../../../../_component/fileUtils'

type Props = {
  isStudentLoading: boolean
  student: Student
  submit: (values: Student, actions: FormikValues) => void
  isExternal?: boolean
}
const StudentEditModalForm: FC<Props> = ({student, isStudentLoading, submit, isExternal}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createStudentSchemas[0])
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber) {
      setSubmitButton(true)
      setCurrentSchema(createStudentSchemas[1])
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    stepper.current.goPrev()

    setCurrentSchema(createStudentSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: Student, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    setCurrentSchema(createStudentSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      if (values.relation_with_guardian === 'father')
        actions.setFieldValue('father', {...values.guardian})
      else if (values.relation_with_guardian === 'mother')
        actions.setFieldValue('mother', {...values.guardian})
      stepper.current.goNext()
    } else {
      actions.setSubmitting(true)
      try {
        if (values.account_user_image_to_upload) {
          const res = await uploadFile(values.account_user_image_to_upload, 'student')
          values.account = {...values.account, user: {...values.account?.user, image: res}}
          delete values.account_user_image_to_upload
        }
        if (values.guardian_image_to_upload) {
          const res = await uploadFile(values.guardian_image_to_upload, 'guardian')
          values.guardian = {...values.guardian, image: res}
          delete values.guardian_image_to_upload
        }
        if (values.father_image_to_upload) {
          const res = await uploadFile(values.father_image_to_upload, 'father')
          values.father = {...values.father, image: res}
          delete values.father_image_to_upload
        }
        if (values.mother_image_to_upload) {
          const res = await uploadFile(values.mother_image_to_upload, 'mother')
          values.mother = {...values.mother, image: res}
          delete values.mother_image_to_upload
        }
        if (values.transfer_certificate_to_upload) {
          const res = await uploadFile(
            values.transfer_certificate_to_upload,
            'transfer_certificate'
          )
          values.transfer_certificate = res
          delete values.transfer_certificate_to_upload
        }
        submit(values, actions)
      } catch (ex) {
        console.error(ex)
      } finally {
        actions.setSubmitting(false)
      }
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Student Info</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Guardian Info</h3>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Father Info</h3>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Mother Info</h3>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Admission Info</h3>
            </div>
          </div>

          <Formik
            enableReinitialize={true}
            validationSchema={currentSchema}
            initialValues={student}
            onSubmit={submitStep}
          >
            {(formik) => (
              <Form className='mx-auto w-100 pt-5 pb-10' id='kt_create_account_form'>
                <div data-kt-stepper-element='content' className='current'>
                  <StudentInfo
                    user={formik.values.account?.user || {}}
                    id={formik.values.id}
                    isExternal={isExternal}
                  />
                </div>
                <div data-kt-stepper-element='content'>
                  <GuardianInfo formik={formik} isExternal={isExternal} />
                </div>
                <div data-kt-stepper-element='content'>
                  <FatherInfo isExternal={isExternal} />
                </div>
                <div data-kt-stepper-element='content'>
                  <MotherInfo isExternal={isExternal} />
                </div>
                <div data-kt-stepper-element='content'>
                  <AdmissionInfo formik={formik} isExternal={isExternal} />
                </div>
                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Back
                    </button>
                  </div>

                  <div>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary me-3'
                      disabled={isStudentLoading || formik.isSubmitting}
                      data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                    >
                      <span className='indicator-label'>
                        {!isSubmitButton && 'Continue'}
                        {isSubmitButton && 'Submit'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {StudentEditModalForm}
