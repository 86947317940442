import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Privilege, PrivilegesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PRIVILEGE_URL = `${API_URL}/privilege`

const getPrivileges = (query: string): Promise<PrivilegesQueryResponse> => {
  return axios.get(`${PRIVILEGE_URL}?${query}`)
}

const getMyPrivileges = (): Promise<PrivilegesQueryResponse> => {
  return axios.get(`${PRIVILEGE_URL}/me`)
}

const getPrivilegeById = (id: ID): Promise<Privilege | undefined> => {
  return axios.get(`${PRIVILEGE_URL}/${id}`)
}

const createPrivilege = (privilege: Privilege): Promise<Privilege | undefined> => {
  return axios.post(PRIVILEGE_URL, privilege)
}

const updatePrivilege = (privilege: Privilege[]): Promise<Privilege[] | undefined> => {
  return axios.patch(`${PRIVILEGE_URL}`, privilege)
}

const deletePrivilege = (privilegeId: ID): Promise<void> => {
  return axios.delete(`${PRIVILEGE_URL}/${privilegeId}`)
}

const deleteSelectedPrivileges = async (privilegeIds: Array<ID>): Promise<void> => {
  const requests = privilegeIds.map((id) => axios.delete(`${PRIVILEGE_URL}/${id}`))
  await axios.all(requests)
}

export {
  getPrivileges,
  getMyPrivileges,
  deletePrivilege,
  deleteSelectedPrivileges,
  getPrivilegeById,
  createPrivilege,
  updatePrivilege,
}
