import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {_Class, ClasssQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/class`

const getClasss = (query: string): Promise<ClasssQueryResponse> => {
  return axios.get(`${USER_URL}?${query}`)
}

const getClassById = (id: ID): Promise<_Class | undefined> => {
  return axios.get(`${USER_URL}/${id}`)
}

const createClass = (_class: _Class): Promise<_Class | undefined> => {
  return axios.post(USER_URL, _class)
}

const updateClass = (_class: _Class): Promise<_Class | undefined> => {
  return axios.patch(`${USER_URL}`, _class)
}

const deleteClass = (classId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${classId}`)
}

const deleteSelectedClasss = async (classIds: Array<ID>): Promise<void> => {
  const requests = classIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  await axios.all(requests)
}

export {getClasss, deleteClass, deleteSelectedClasss, getClassById, createClass, updateClass}
