import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {useAuth} from '../core/Auth'

const initialValues = {
  email: '',
  reset_key: undefined,
  password: undefined,
  confirm_password: undefined,
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const {school} = useAuth()
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
    reset_key: Yup.number()
      .when('email', {
        is: (val: string) => val && sent,
        then: (schema) => schema.required(),
      })
      .label('Reset Key'),
    password: Yup.string()
      .when('email', {
        is: (val: string) => val && sent,
        then: (schema) => schema.required(),
      })
      .label('Password'),
    confirm_password: Yup.string()
      .when('email', {
        is: (val: string) => val && sent,
        then: (schema) =>
          schema.required().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      })
      .label('Confirm Password'),
  })
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email, school?.id, values.reset_key, values.password)
          .then((data) => {
            if (values.reset_key) navigate('/auth/login')
            setSent(true)
            setHasErrors(false)
            setLoading(false)
          })
          .catch((e) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        {sent ? (
          <>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid d-none',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Reset Key</label>
              <input
                type='number'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('reset_key')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.reset_key && formik.errors.reset_key},
                  {
                    'is-valid': formik.touched.reset_key && !formik.errors.reset_key,
                  }
                )}
              />
              {formik.touched.reset_key && formik.errors.reset_key && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.reset_key}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>New Passowrd</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>
                Confirm New Password
              </label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('confirm_password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password},
                  {
                    'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                  }
                )}
              />
              {formik.touched.confirm_password && formik.errors.confirm_password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirm_password}</span>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        )}
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
