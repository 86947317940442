/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {QUERIES} from '../../../../_metronic/helpers'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useQuery} from '@tanstack/react-query'
import {getAccountCountByRole} from '../core/_request'

type Props = {
  className: string
}

const AccountStat: React.FC<Props> = ({className}) => {
  const {data: response} = useQuery([`${QUERIES.SUMMARY_LIST}-get_account_count_by_role`], () => {
    return getAccountCountByRole()
  }, {cacheTime: Infinity, keepPreviousData: true, refetchOnWindowFocus: false})
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, response])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(response))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Active Accounts</span>

          {/* <span className='text-muted fw-semibold fs-7'>More than 400 new members</span> */}
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_1_chart' />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AccountStat}

function getChartOptions(response?: {name: string; count: number}[]): ApexOptions {
  return {
    series: response ? response.map((r) => r.count) : [],
    chart: {
      fontFamily: 'inherit',
      width: 380,
      type: 'pie',
    },
    labels: response ? response.map((r) => `${r.name}: ${r.count}`) : [],
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }
}
