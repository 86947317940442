import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {About} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ABOUT_URL = `${API_URL}/about`

const getAbouts = (): Promise<About> => {
  return axios.get(`${ABOUT_URL}`)
}

const getAboutById = (id: ID): Promise<About | undefined> => {
  return axios.get(`${ABOUT_URL}/${id}`)
}

const createAbout = (about: About): Promise<About | undefined> => {
  return axios.post(ABOUT_URL, about)
}

const updateAbout = (about: About): Promise<About | undefined> => {
  return axios.patch(`${ABOUT_URL}`, about)
}

const deleteAbout = (aboutId: ID): Promise<void> => {
  return axios.delete(`${ABOUT_URL}/${aboutId}`)
}

const deleteSelectedAbouts = async (aboutIds: Array<ID>): Promise<void> => {
  const requests = aboutIds.map((id) => axios.delete(`${ABOUT_URL}/${id}`))
  await axios.all(requests)
}

export {getAbouts, deleteAbout, deleteSelectedAbouts, getAboutById, createAbout, updateAbout}
