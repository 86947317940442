import * as Yup from 'yup'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Account} from '../../../account/accounts-list/core/_models'
import {Enrollment, initialEnrollment} from '../../../enrollment/enrollments-list/core/_models'
import {StudentType} from '../../../student-type/student-types-list/core/_models'
import {initialUser, User} from '../../../user/users-list/core/_models'

export type Student = {
  id?: ID
  admission_no?: string
  admission_date?: Date
  relation_with_guardian?: string
  registration_no?: string
  group?: string
  is_library_member?: boolean
  is_hostel_member?: boolean
  is_transport_member?: boolean
  previous_school?: string
  previous_class?: string
  transfer_certificate?: string
  status?: 'regular' | 'drop' | 'transfer' | 'passed' | 'new' | 'waiting' | 'declined' | 'approved'
  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
  created_by?: string
  updated_by?: string
  father_id?: number
  mother_id?: number
  guardian_id?: number
  account_id?: number
  school_id?: string
  student_type_id?: number
  discount_id?: number
  enrollment_id?: number
  account?: Account
  father?: User
  mother?: User
  guardian?: User
  enrollment?: Enrollment
  student_type?: StudentType
  account_user_image_to_upload?: File
  guardian_image_to_upload?: File
  father_image_to_upload?: File
  mother_image_to_upload?: File
  transfer_certificate_to_upload?: File
}

export type StudentsQueryResponse = Response<Array<Student>>

export const initialStudent: Student = {
  admission_no: '',
  admission_date: new Date(),
  registration_no: '',
  previous_school: '',
  previous_class: '',
  relation_with_guardian: undefined,
  account: {user: {...initialUser, status: false}},
  father: {...initialUser},
  mother: {...initialUser},
  guardian: {...initialUser, status: false},
  enrollment: {...initialEnrollment},
}

export const createStudentSchemas = [
  Yup.object({
    account: Yup.object().shape({
      user: Yup.object().shape({
        first_name: Yup.string().required().label('First Name'),
        last_name: Yup.string().required().label('Last Name'),
        other_name: Yup.string(),
        email: Yup.string().email().label('Email'),
        phone: Yup.string(),
        gender: Yup.string().required().label('Gender'),
        birthday: Yup.date(),
        blood_group: Yup.string(),
        religion: Yup.string(),
        caste: Yup.string(),
        national_id: Yup.string(),
        address1: Yup.string().required().label('Address'),
        address2: Yup.string(),
        status: Yup.boolean(),
      }),
    }),
  }),
  Yup.object({
    guardian: Yup.object().shape({
      first_name: Yup.string().required().label('First Name'),
      last_name: Yup.string().required().label('Last Name'),
      email: Yup.string().required().email().label('Email'),
      phone: Yup.string().required().label('Phone Number'),
      highest_education: Yup.string(),
      id: Yup.number(),
      profession: Yup.string(),
      national_id: Yup.string(),
      file: Yup.string(),
      address1: Yup.string().required().label('Address'),
      address2: Yup.string(),
      status: Yup.boolean(),
    }),
    relation_with_guardian: Yup.string().required().label('Relationship with Student'),
  }),
  Yup.object({
    father: Yup.object().shape({
      first_name: Yup.string().required().label('First Name'),
      last_name: Yup.string().required().label('Last Name'),
      email: Yup.string().email().label('Email'),
      phone: Yup.string(),
      highest_education: Yup.string(),
      id: Yup.number(),
      profession: Yup.string(),
      relationship: Yup.string(),
      file: Yup.string(),
    }),
  }),
  Yup.object({
    mother: Yup.object().shape({
      first_name: Yup.string().required().label('First Name'),
      last_name: Yup.string().required().label('Last Name'),
      email: Yup.string().email().label('Email'),
      phone: Yup.string(),
      highest_education: Yup.string(),
      id: Yup.number(),
      profession: Yup.string(),
      relationship: Yup.string(),
      file: Yup.string(),
    }),
  }),
  Yup.object().shape({
    admission_no: Yup.string(),
    admission_date: Yup.date(),
    registration_no: Yup.string(),
    group: Yup.string(),
    previous_school: Yup.string(),
    previous_class: Yup.string(),
    transfer_certificate: Yup.string(),
    student_type_id: Yup.number().required().label('Student Type'),
    enrollment: Yup.object().shape({
      class_id: Yup.number().required().label('Class'),
      section_id: Yup.number().required().label('Section'),
    }),
  }),
]
