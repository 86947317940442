import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {StudentType, StudentTypesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const STUDENT_TYPE_URL = `${API_URL}/student-type`

const getStudentTypes = (query: string): Promise<StudentTypesQueryResponse> => {
  return axios.get(`${STUDENT_TYPE_URL}?${query}`)
}

const getStudentTypeById = (id: ID): Promise<StudentType | undefined> => {
  return axios.get(`${STUDENT_TYPE_URL}/${id}`)
}

const createStudentType = (studentType: StudentType): Promise<StudentType | undefined> => {
  return axios.post(STUDENT_TYPE_URL, studentType)
}

const updateStudentType = (studentType: StudentType): Promise<StudentType | undefined> => {
  return axios.patch(`${STUDENT_TYPE_URL}`, studentType)
}

const deleteStudentType = (studentTypeId: ID): Promise<void> => {
  return axios.delete(`${STUDENT_TYPE_URL}/${studentTypeId}`)
}

const deleteSelectedStudentTypes = async (studentTypeIds: Array<ID>): Promise<void> => {
  const requests = studentTypeIds.map((id) => axios.delete(`${STUDENT_TYPE_URL}/${id}`))
  await axios.all(requests)
}

export {
  getStudentTypes,
  deleteStudentType,
  deleteSelectedStudentTypes,
  getStudentTypeById,
  createStudentType,
  updateStudentType,
}
