import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {News, NewsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const NEWS_URL = `${API_URL}/news`

const getNews = (query: string): Promise<NewsQueryResponse> => {
  return axios.get(`${NEWS_URL}?${query}`)
}

const getNewsById = (id: ID): Promise<News | undefined> => {
  return axios.get(`${NEWS_URL}/${id}`)
}

const createNews = (news: News): Promise<News | undefined> => {
  return axios.post(NEWS_URL, news)
}

const updateNews = (news: News): Promise<News | undefined> => {
  return axios.patch(`${NEWS_URL}`, news)
}

const deleteNews = (newsId: ID): Promise<void> => {
  return axios.delete(`${NEWS_URL}/${newsId}`)
}

const deleteSelectedNews = async (newsIds: Array<ID>): Promise<void> => {
  const requests = newsIds.map((id) => axios.delete(`${NEWS_URL}/${id}`))
  await axios.all(requests)
}

export {getNews, deleteNews, deleteSelectedNews, getNewsById, createNews, updateNews}
