import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {GetUser, ImageUpload} from '../../../../../../../../../_component/CustomInput'

const MotherInfo: FC<{isExternal: boolean | undefined}> = ({isExternal}) => {
  return (
    <div className='w-100'>
      <div className='row'>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Mother Email</label>
          {isExternal ? (
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='mother[email]'
            />
          ) : (
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='mother[email]'
              component={GetUser}
            />
          )}
          <div className='text-danger mt-2'>
            <ErrorMessage name='mother[email]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Mother First Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='mother[first_name]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='mother[first_name]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3 required'>Mother Last Name</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='mother[last_name]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='mother[last_name]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Mother Phone</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='mother[phone]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='mother[phone]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Mother Highest Education</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='mother[highest_education]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='mother[highest_education]' />
          </div>
        </div>
        <div className='mb-5 fv-row col-lg-4'>
          <label className='form-label mb-3'>Mother Profession</label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='mother[profession]'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='mother[profession]' />
          </div>
        </div>
        <div className='fv-row mb-7 col-lg-4'>
          {/* begin::Label */}
          <label className='d-block form-label mb-3'>Mother Image</label>
          {/* end::Label */}

          {/* begin::Image input */}
          <Field
            type='text'
            component={ImageUpload}
            className='form-control form-control-lg form-control-solid'
            name='mother[image]'
          />
          {/* end::Image input */}

          {/* begin::Hint */}
          {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
          {/* end::Hint */}
        </div>
      </div>
    </div>
  )
}

export {MotherInfo}
