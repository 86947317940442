import {Cloudinary} from '@cloudinary/url-gen'
import axios from 'axios'
import dayjs from 'dayjs'
import {toast} from 'react-toastify'
import {signS3} from './_request'

type FileProps = {
  file: File
  api_key: string
  timestamp: string
  signature: string
  folder: string
}
const get_signed_url = async (
  folder?: string
): Promise<{
  signature: string
  timestamp: string
  cloudname: string
  apikey: string
  folder: string
}> => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/upload_sign_url${folder ? `?folder=${folder}` : ''}`
  )
}
const upload_file = async (data: FileProps, cloudname: string): Promise<any> => {
  const new_axios = axios.create({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return await new_axios.post('https://api.cloudinary.com/v1_1/' + cloudname + '/auto/upload', data)
}
//Upload File
export const fileUpload = async (file: File, folder?: string) => {
  if (file.size > 10485760) {
    toast.error('File size should not exceed 10MB')
    return
  }
  try {
    const signData = await get_signed_url(folder)
    const result = await upload_file(
      {
        file,
        api_key: signData.apikey,
        timestamp: signData.timestamp,
        signature: signData.signature,
        folder: signData.folder || 'School',
      },
      signData.cloudname
    )
    return result.data.public_id.split('School/')[1]
    // return {
    //   status: true,
    //   data: {
    //     id: result.data.public_id,
    //     format: result.data.format,
    //     provider: 'cloudinary',
    //   },
    // }
  } catch (error) {
    return
  }
}

//Get File Url
export const fileSrc2 = (id: string, height?: number, width?: number) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
    },
  })
  let transformation = 'c_fill,'
  let url = cld.image('School/' + id)
  if (height) transformation += `h_${height},`
  if (width) transformation += `w_${width},`
  url.addTransformation(transformation)
  return url.toURL()
}

export const fileSrc = (id: string) => {
  return process.env.REACT_APP_BUCKET_URL + id
}

// s3
export const uploadFile = async (
  file: File,
  folder?: string,
  setProgress?: (val: number) => void
) => {
  try {
    const fileName = dayjs().format('YYYYMMDDHHss') + '_' + file.name
    const fileType = file.type
    const fileData = await signS3({
      fileName,
      fileType,
      folder,
    })
    var instance = axios.create()
    delete instance.defaults.headers.common['Authorization']
    var options = {
      headers: {
        'Content-Type': fileType,
      },
      onUploadProgress: (progressEvent: ProgressEvent) =>
        setProgress
          ? setProgress(Number(((progressEvent.loaded * 100) / progressEvent.total).toFixed(2)))
          : '',
    }
    await instance.put(fileData.signed_request, file, options)
    return fileData.file_name
  } catch (error: any) {
    throw error.message
  }
}
